<template>
  <v-menu
    open-on-click
    content-class="pattern-header-menu"
    offset-y
    bottom
    :close-on-content-click="false"
  >
    <template v-slot:activator="props">
      <slot
        name="activator"
        v-bind="props"
      > </slot>
    </template>

    <v-card>
      <div class="d-flex flex-column elevation-4">
        <v-text-field
          v-if="items.length > 0"
          dense
          outlined
          hide-details="auto"
          v-model="search"
          prepend-icon="mdi-magnify"
          placeholder="Search..."
          class="pa-3"
          data-test-id="patternHeaderMenuSearch"
        />
        <v-divider v-if="items.length > 0" />
      </div>
      <div class="d-flex pattern-header-menu-list">
        <v-subheader
          class="flex-grow-1 justify-center"
          v-if="displayItems.length === 0"
        >{{ noDataText }}</v-subheader>
        <v-list
          v-else
          nav
          dense
          class="d-flex flex-column flex-grow-1"
        >
          <v-list-item-group class="d-flex flex-grow-1 flex-column">
            <v-subheader class="flex-grow-1 justify-start">
              {{ filteredItems.length }} items found.
              {{ showAllItemsBtn ? "(First 50 shown)" : "" }}
            </v-subheader>
            <v-list-item
              v-for="(item, index) in displayItems"
              :key="index"
              @click="$emit('click:item', { search, ...item })"
            >
              <slot
                v-bind:item="item"
                name="item"
              > </slot>
            </v-list-item>
            <v-subheader
              v-if="showAllItemsBtn"
              class="flex-grow-1 justify-start"
            >
              and {{ filteredItems.length - maxItems }} more...
            </v-subheader>
            <v-list-item
              v-if="showAllItemsBtn"
              class="mb-1"
            >
              <div
                class="
                  primary--text
                  text-button
                  d-flex
                  flex-grow-1
                  justify-center
                "
                @click="$emit('show-all', { search })"
              >
                <v-icon
                  color="primary"
                  left
                >mdi-view-list</v-icon> SHOW ALL
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    noDataText: {
      type: String,
      required: false,
      default: "No data found",
    },

    itemKey: {
      type: String,
      required: false,
      default: "key",
    },

    maxItems: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      search: "",
    };
  },

  computed: {
    filteredItems() {
      let items = this.items;
      if (this.search) {
        //Simple wildcard search, see https://stackoverflow.com/a/32402438
        //eslint-disable-next-line
        const escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        items = items.filter((item) => {
          let text = this.$isPlainObject(item) ? item[this.itemKey] : item;
          return (
            text.includes(this.search) ||
            new RegExp(
              "^" + this.search.split("*").map(escapeRegex).join(".*") + "$"
            ).test(text)
          );
        });
      }
      return items;
    },

    displayItems() {
      let filteredItems = this.filteredItems;
      const maxItems = this.maxItems;
      if (maxItems && filteredItems.length > maxItems) {
        return filteredItems.slice(0, maxItems);
      }
      return filteredItems;
    },

    showAllItemsBtn() {
      const itemsLength = this.filteredItems.length;
      return this.maxItems && itemsLength > 0 && itemsLength > this.maxItems;
    },
  },
};
</script>
<style scoped>
.pattern-header-menu-list {
  max-height: 300px;
  overflow: scroll;
}
</style>