<template>
  <v-container fluid class="catalog-rule-container">
    <v-toolbar flat dense>
      <v-toolbar-title>{{ rule.id }}</v-toolbar-title>
      <v-divider vertical inset class="mx-4"></v-divider>
      {{ isFilterRule ? "FILTER RULE" : "SHARING RULE" }}
      <v-spacer></v-spacer>
      <div v-if="runningAction" class="px-4">
        <v-progress-circular
          indeterminate
          :size="24"
          :width="2"
          color="grey darken-3"
        />
      </div>
      <v-btn
        v-if="isFilterRule"
        :disabled="disabled || runningAction"
        @click="upsertRule"
        color="green"
        outlined
        :data-test-id="'rule_' + rule.id + '_save_btn'"
      >
        Save
      </v-btn>
      <v-btn
        :disabled="disabled || runningAction"
        @click="deleteRule"
        color="red"
        :text="isFilterRule"
        :outlined="!isFilterRule"
        :data-test-id="'rule_' + rule.id + '_delete_btn'"
      >
        Delete
      </v-btn>
    </v-toolbar>
    <v-container fluid class="catalog-rule">
      <v-list>
        <v-list-item>
          <v-list-item-icon
            ><v-icon>mdi-book-open-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a
                data-test-id="ruleTargetCatalog"
                :href="targetCatalogHref"
                @click.stop.prevent="openTargetCatalogDetail"
              >
                {{ targetCatalogName }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>Target catalog</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <ProductOverview
        v-if="isFilterRule && srcCatalog"
        :query="JSON.parse(rule.filter)"
        :catalog="srcCatalog"
        open-details-in-tab
        @query-changed="changeFilter"
        class="rule-product-overview"
      />
    </v-container>
  </v-container>
</template>

<script>
export default {
  components: {
    ProductOverview: () => import("../../products/ProductOverview.vue"),
  },

  provide() {
    return {
      //provide page height, so that the product overview
      //calculates the correct table height
      getPageHeight: () => {
        return 450;
      },
    };
  },

  props: {
    catalogRule: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      rule: this.catalogRule,
      srcCatalog: null,
      changed: false,
      runningAction: false,
      targetCatalog: null,
    };
  },

  async mounted() {
    try {
      this.runningAction = true;
      await this.loadTargetCatalog();
      if (this.isFilterRule) await this.loadSourceCatalog(this.rule.srcCatalog);
    } finally {
      this.runningAction = false;
    }
  },

  methods: {
    async loadSourceCatalog(catalogId) {
      const srcCatalog = await this.$store.$coreApi.coreCatalogApi.getCatalog(
        this.selectedDomain,
        catalogId
      );
      this.srcCatalog = srcCatalog;
    },

    async loadTargetCatalog() {
      const domain = this.rule?.catalogDomain ?? this.selectedDomain;
      const targetCatalog =
        await this.$store.$coreApi.coreCatalogApi.getCatalog(
          domain,
          this.rule.catalog
        );
      this.targetCatalog = targetCatalog;
    },

    changeFilter(query) {
      this.rule.filter = JSON.stringify(query);
    },

    async deleteRule() {
      if (
        !(await this.$confirm(
          "Delete rule?",
          "Are you sure you want to delete rule " +
            this.rule.id +
            "? This cannot be undone."
        ))
      )
        return;
      this.runningAction = true;
      try {
        const method = this.isFilterRule
          ? "deleteFilterRule"
          : "deleteSharingRule";
        const res = await this.$store.$coreApi.coreCatalogApi[method](
          this.selectedDomain,
          this.rule.id
        );
        if (!res.ok) return;
        this.$emit("deleted", this.rule);
      } finally {
        this.runningAction = false;
      }
    },

    async upsertRule() {
      this.runningAction = true;
      try {
        const method = this.isFilterRule
          ? "updateFilterRule"
          : "updateSharingRule";
        const res = await this.$store.$coreApi.coreCatalogApi[method](
          this.selectedDomain,
          this.rule
        );
        if (!res?.ok) return;
        this.changed = false;
        this.$emit("saved", this.rule);
      } finally {
        this.runningAction = false;
      }
    },

    async openTargetCatalogDetail() {
      await this.$router.push(this.targetCatalogRoute);
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },
    isFilterRule() {
      return this.catalogRule.filter !== undefined;
    },

    targetCatalogName() {
      return this.targetCatalog?.name ?? this.rule.catalog;
    },

    targetCatalogRoute() {
      return {
        name: "catalogDetail",
        params: {
          domain: this.rule?.catalogDomain ?? this.selectedDomain,
          catalog: this.rule?.catalog,
          keepParams: true,
        },
      };
    },

    targetCatalogHref() {
      return this.$router.resolve(this.targetCatalogRoute).href;
    },
  },
};
</script>

<style scoped>
.catalog-rule-container {
  max-height: 600px;
}

.catalog-rule-container .rule-product-overview {
  height: 450px;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.catalog-rule-container
  .rule-product-overview::v-deep
  .main-overview-container {
  margin: 0;
}

.catalog-rule-container .v-btn {
  margin-left: 5px;
}

.catalog-rule > .v-list {
  text-align: left;
}
</style>