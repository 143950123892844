<template>
  <v-tooltip v-model="showViolations" bottom allow-overflow>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        class="violation-alert"
        data-test-id="violationAlertIcon"
        :left="alignment === 'left'"
        :right="alignment === 'right'"
        :color="color"
      >
        mdi-alert
      </v-icon>
    </template>
    <ol v-if="violationList.length > 0" data-test-id="violationsList">
      <li v-for="({ property, message }, index) in violationList" :key="index">
        <span :data-test-id="'violation_' + property">{{
          property + ": " + message
        }}</span>
      </li>
    </ol>
    <span v-else v-html="message" data-test-id="violationMsg" />
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },

    violation: {
      type: Object,
      required: true,
    },

    color: {
      type: String,
      required: false,
      default: "error",
    },

    alignment: {
      type: String,
      required: false,
      validator: (alignment) => {
        return ["left", "right"].includes(alignment);
      },
    },
  },

  data() {
    return {
      showViolations: this.value,
      timeout: 0,
    };
  },

  mounted() {
    this.displayViolations();
  },

  watch: {
    value(value) {
      this.showViolations = value;
    },

    showViolations(isShown) {
      this.$emit("input", isShown);
    },

    violation: {
      handler() {
        this.displayViolations();
      },
      deep: true,
    },
  },

  methods: {
    displayViolations() {
      this.showViolations = true;
      this.timeout = setTimeout(() => {
        this.showViolations = false;
      }, 5000);
    },
  },

  computed: {
    violationList() {
      return this.violation?.violations ?? [];
    },

    message() {
      return this.$sanitize(this.violation?.message);
    },
  },
};
</script>

<style scoped>
</style>