<template>
  <v-container fluid class="price-calendar-container">
    <Calendar :events="priceEvents" :loading="loading">
      <template #dialog="{ event }">
        <div class="price-dialog" data-test-id="productPriceDialog">
          <v-toolbar :color="event.color" flat dense>
            <v-tooltip top>
              {{ event.name }}
              <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title v-on="on" v-bind="attrs">
                  {{ event.name }}
                </v-toolbar-title>
              </template>
            </v-tooltip>
          </v-toolbar>
          <v-container class="product-price-dialog-details">
            <v-row v-if="!event.valid">
              <v-col>
                <v-icon left color="red">mdi-alert</v-icon>
                Price is outside of validity period
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-currency-usd</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          $parseFractionUnitToString(
                            event.price,
                            event.currency
                          )
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Price</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-calendar</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $getLocalizedDate(event.start, {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                      }}</v-list-item-title>
                      <v-list-item-subtitle>From Date</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-calendar</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $getLocalizedDate(event.end, {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                      }}</v-list-item-title>
                      <v-list-item-subtitle>Until Date</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>
    </Calendar>
  </v-container>
</template>
<script>
import Calendar from "components/common/templates/Calendar";
import validityPeriodsMixin from "mixins/validity-periods-mixin.js";
export default {
  mixins: [validityPeriodsMixin],

  components: {
    Calendar,
  },

  props: {
    prices: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    validityPeriods: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    showNames: {
      //if true, prices will always display the given names
      type: Boolean,
      required: false,
      default: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      priceEvents: [],
    };
  },

  mounted() {
    this.calculatePrices();
  },

  watch: {
    prices: {
      handler: function () {
        this.calculatePrices();
      },
      deep: true,
    },
  },

  methods: {
    calculatePrices() {
      if (this.noData) return;
      let pricesPerSku = {};
      //group all dates per price and all prices per SKU
      this.prices.forEach((p) => {
        const priceProps = p?._source ?? p;
        const sku = priceProps.sku ?? priceProps.id;
        const price = priceProps.value;
        const untilDate = new Date(priceProps.untilDate);
        const fromDate = new Date(priceProps.fromDate);
        const currency = priceProps.currency;
        if (!pricesPerSku[sku]) pricesPerSku[sku] = {};
        if (!pricesPerSku[sku][price])
          pricesPerSku[sku][price] = { currency, dates: [] };
        const validityPeriods = this.validityPeriods?.[sku];
        //add all dates between from and until date to get the correct price ranges
        this.addDates(
          fromDate,
          untilDate,
          pricesPerSku[sku][price].dates,
          validityPeriods
        );
        pricesPerSku[sku][price].dates.sort((a, b) => {
          return a.date - b.date;
        });
      });

      this.parsePricesToEvents(pricesPerSku);
    },

    parsePricesToEvents(pricesPerSku) {
      this.priceEvents = [];
      let idx = 0;
      const products = Object.keys(pricesPerSku);
      //Iterate over all different products and their prices
      products.forEach((sku) => {
        let color = "grey";

        if (idx <= this.colors.length - 1) {
          color = this.colors[idx];
          idx++;
        }

        //Iterate over all prices of this product
        const prices = pricesPerSku[sku];
        Object.keys(prices).forEach((price) => {
          let events = [];
          const priceObject = pricesPerSku[sku][price];
          const currency = priceObject.currency;
          let previousDate = null;
          let previousDateValid = null;

          //show the sku in event name if there is more than one product
          const name =
            (this.showNames || products.length > 1 ? sku + ": " : "") +
            this.$parseFractionUnitToString(price, currency);

          let currentEvent = {
            name,
            price,
            currency,
            color,
            timed: false,
            valid: true,
          };

          //Iterate over price dates and create events
          const priceDates = priceObject.dates;

          if (priceDates.length === 1) {
            //the price occurs on exactly one day, so add
            //the dates and validity to the event manually
            const dateObject = priceDates[0];
            currentEvent.start = dateObject.date;
            currentEvent.end = dateObject.date;
            currentEvent.valid = dateObject.valid;
            currentEvent.color = currentEvent.valid ? color : "grey";
          } else {
            //price occurs on multiple days, so create the events for the time ranges
            priceDates.forEach(({ date, valid }) => {
              let dateString = this.$formatToISO(date, false);

              if (previousDate) {
                const diffInDays = this.$getDayDiff(previousDate, date);
                if (
                  diffInDays <= 1 &&
                  diffInDays >= 0 &&
                  valid === previousDateValid
                ) {
                  //dates are next to each other or on the same day and have the same validity,
                  //so update end date of current event
                  currentEvent.end = dateString;
                } else {
                  //dates are not next to each other, so push current one and create new event
                  if (!currentEvent.end) currentEvent.end = currentEvent.start;
                  events.push(currentEvent);
                  if (valid !== previousDateValid) {
                    //events have different validity, so set the start date
                    //of the new event to one day after the current
                    const endDate = new Date(currentEvent.end);
                    const newEventStart = this.addDays(endDate, 1);
                    dateString = this.$formatToISO(newEventStart, false);
                  }
                  currentEvent = {
                    name,
                    price,
                    currency,
                    color: valid ? color : "grey",
                    timed: false,
                    start: dateString,
                    end: dateString,
                    valid,
                  };
                }
              } else {
                currentEvent.start = dateString;
                currentEvent.valid = valid;
                currentEvent.color = valid ? color : "grey";
              }
              previousDate = date;
              previousDateValid = valid;
            });
          }

          //push the last event
          events.push(currentEvent);

          this.priceEvents = this.priceEvents.concat(events);
        });
      });
    },
  },

  computed: {
    colors() {
      return [
        "psblue",
        "slpink",
        "psgreen",
        "red",
        "orange",
        "brown",
        "pink",
        "indigo",
        "deep-purple",
        "cyan",
      ];
    },

    noData() {
      return !this.prices || this.prices.length === 0;
    },
  },
};
</script>
<style scoped>
.price-dialog {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>