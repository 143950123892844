<template>
  <MasterDetail data-test-id="productPage" :detail-open="!!selectedProduct">
    <template #toolbar>
      <Toolbar
        v-model="filterQuery"
        :key="possibleFilters.length"
        :disabled="disabled"
        :searchProps="internalSearchProps"
        class="product-table-toolbar"
      >
        <template #extended>
          <div class="extended-toolbar">
            <v-select
              v-if="!hidePriceData"
              v-model="limit"
              class="products-limit-select"
              dense
              hide-details
              :items="pageLimits"
              :disabled="runningAction || disabled"
              data-test-id="pageLimitSelector"
            />
            rows
            <v-divider class="mx-4" vertical></v-divider>

            <!-- CUSTOM PAGINATION COMPONENT -->
            <PaginationComponent
              v-if="totalPages > 0"
              v-model="currentPage"
              :totalPages="totalPages"
              :disabled="runningAction || disabled"
            />
            <v-divider class="mx-4" v-if="totalPages > 0" vertical></v-divider>
            <span>
              <strong>{{ total }}</strong> total
              {{ total > 10000 ? "(showing first 10 000)" : "" }}
            </span>
            <v-spacer></v-spacer>
            <v-switch
              v-if="!selectedProduct && !catalog"
              :input-value="showPriceData"
              :disabled="runningAction || disabled"
              data-test-id="priceDataSwitch"
              label="Show price data"
              color="primary"
              hide-details
              class="mt-0 pt-0"
              @click="togglePriceData"
            ></v-switch>
          </div>
        </template>
      </Toolbar>
    </template>
    <template #table>
      <v-data-table
        dense
        :items="products"
        :headers="headers"
        :loading="runningAction"
        :server-items-length="total"
        :single-select="true"
        :options.sync="options"
        :height="tableHeight"
        class="product-table"
        hide-default-footer
        disable-pagination
        fixed-header
        multi-sort
        ref="productTable"
      >
        <template v-slot:item="{ item }">
          <tr
            v-if="headers"
            :class="{
              selected:
                selectedProduct &&
                item._source.sku === selectedProduct._source.sku &&
                item._index === selectedProduct._index,
            }"
            :data-test-id="'product_table_row_' + item._source.sku"
            :ref="item._source.sku"
            @click="!runningAction && !disabled && openProductDetail(item)"
          >
            <!-- eslint-disable-next-line -->
            <td
              v-for="(header, index) in headers"
              :data-test-id="
                'product_' +
                header.value.replace('_source.', '') +
                '_' +
                item._source.sku
              "
              :key="index"
            >
              <span v-if="!header.isPrice">{{
                $getObjectValueByPath(item, header.value)
              }}</span>
              <span v-else>
                {{
                  $parseFractionUnitToString(
                    $getObjectValueByPath(item, header.value),
                    item._source.currency
                  )
                }}
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
    <template #detail>
      <ProductDetail
        v-model="selectedProduct._source"
        :key="selectedProduct._id + selectedProduct._index"
        :index="selectedProduct._index"
        :style="{
          height: detailHeight + 'px',
        }"
        data-test-id="productDetail"
        @close="closeProductDetail"
      />
    </template>
  </MasterDetail>
</template>

<script>
import PaginationComponent from "../PaginationComponent";
import Toolbar from "../common/templates/Toolbar";
import ProductDetail from "./ProductDetail";
import esMixin from "../../mixins/elastic-search-mixin";
import MasterDetail from "../common/templates/MasterDetail.vue";
import mainOverviewMixin from "../../mixins/main-overview-mixin";
import sortQueryMixin from "mixins/sort-query-mixin";

export default {
  name: "ProductOverview",

  mixins: [esMixin, mainOverviewMixin, sortQueryMixin],

  components: {
    PaginationComponent,
    ProductDetail,
    Toolbar,
    MasterDetail,
  },

  props: {
    catalog: {
      type: Object,
      required: false,
    },

    query: {
      type: Object,
      required: false,
      default: () => {
        return null;
      },
    },

    openDetailsInTab: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    searchProps: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    hidePriceData: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      products: [],
      selectedProduct: null,
      searchIndex: "product",
      showPriceData: false,
      runningAction: false,
      currentPage: 1,
      total: 0,
      limit: 20,
      filterQuery: this.query,
      options: {},
      loadProductsController: null,
    };
  },

  async created() {
    await this.init();
    const sku = this.$route?.params?.product;
    if (sku) {
      this.$nextTick(() => {
        //scroll to the selected product
        const element = this.$refs?.[sku];
        if (!element) return;
        const pos = element.style.position;
        const top = element.style.top;
        element.style.position = "relative";
        element.style.top = "-100px";
        element.scrollIntoView();
        element.style.top = top;
        element.style.position = pos;
      });
    }
  },

  watch: {
    async showPriceData(show) {
      this.runningAction = true;

      if (show && !this.hidePriceData) this.searchIndex = "price";
      else this.searchIndex = "product";

      const query = Object.assign({}, this.$route.query, {
        index: this.searchIndex,
      });

      await this.$router.push({ ...this.$route, query });

      //recalculate the current page number according to the total pages and the page row limit
      if (this.currentPage > this.totalPages && this.totalPages > 0) {
        //change of current page triggers a reload
        this.currentPage = this.totalPages;
      } else {
        //current page did not change, so reload customers manually
        await this.loadProducts();
      }
    },

    async currentPage(page) {
      //reload customers if the current page changes
      if (page < 1) page = 1;
      if (page > 0 && page <= this.totalPages) {
        await this.loadProducts();
        if (this.isMainComponent) {
          await this.$router.push({
            ...this.$route,
            query: Object.assign({}, this.$route.query, { page }),
          });
        }
      }
    },

    totalPages(totalPages) {
      if (this.currentPage > totalPages && totalPages > 0) {
        this.currentPage = totalPages;
      }
    },

    async filterQuery() {
      if (this.isMainComponent) {
        let query = Object.assign({}, this.$route.query);
        //remove filter query if the query is faulty
        let hasFilters = this.filterQuery?.bool?.filter?.length > 0;
        let hasSearchString =
          this.filterQuery?.bool?.must?.simple_query_string?.query;
        if (hasFilters || hasSearchString) {
          query.filter = this.$urlEncode(JSON.stringify(this.filterQuery));
        } else {
          if (query.filter) delete query.filter;
        }
        await this.$router.replace({ ...this.$route, query });
      }

      await this.loadProducts();
      this.$emit("query-changed", this.filterQuery);
    },

    async limit(limit) {
      if (this.isMainComponent) {
        await this.$router.push({
          ...this.$route,
          query: Object.assign({}, this.$route.query, { limit }),
        });
      }

      if (this.totalPages === 0) return;

      //recalculate the current page number according to the total pages and the page row limit
      if (this.currentPage > this.totalPages) {
        //change of current page triggers a reload
        this.currentPage = this.totalPages;
      } else {
        //current page did not change, so reload customers manually
        await this.loadProducts();
      }
    },

    async options(options, old) {
      if (
        this.$isEqual(options.sortBy, old.sortBy) &&
        this.$isEqual(options.sortDesc, old.sortDesc)
      ) {
        //If sortBy and sortDesc did not change, do not update the query
        return;
      }

      //prevent the init of options from triggering
      //a product reload
      if (Object.keys(old).length === 0) return;
      let query = Object.assign({}, this.$route.query);
      const sort = this.parseOptionsToSortQuery(options);
      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);
      if (this.isMainComponent) {
        await this.$router.push({ ...this.$route, query });
      }
      await this.loadProducts();
    },
  },

  methods: {
    async init() {
      //If the code is changed here, check in OrderOverview and CustomerPage if it also needs changing there

      //initialize the component with the route parameters
      const namedRoute = this.$route.name;

      if (
        this.$route.matched.some(({ name }) => name === "catalogs") &&
        !this.catalog
      ) {
        //open the catalog dialog if the url matches named route 'catalogs' or one of its children
        this.catalogDialog = true;
      } else {
        this.catalogDialog = false;
        let pageChange = false;
        let filterChange = false;
        let indexChange = false;

        if (this.isMainComponent) {
          //get the query parameters and update the according variables
          let routeQuery = this.$route.query;
          let page = routeQuery.page;
          let limit = routeQuery.limit;
          let filter = routeQuery.filter;
          let index = routeQuery.index;
          let sort = routeQuery.sort;

          if (index) {
            if (this.searchIndex != index) indexChange = true;
            this.searchIndex = index;
          }

          if (limit) {
            limit = parseInt(limit, 10);
            if (Number.isNaN(limit) || !this.pageLimits.includes(limit))
              limit = this.pageLimits[0];
            this.limit = limit;
          }

          if (page) {
            page = parseInt(page, 10);
            if (Number.isNaN(page)) page = 1;
            if (page != this.currentPage) pageChange = true;
            this.currentPage = page;
          }

          if (filter) {
            try {
              filter = this.$urlDecode(filter);
              this.filterQuery = JSON.parse(filter);
              filterChange = true;
            } catch (e) {
              this.filterQuery = null;
              console.warn(e);
            }
          }

          if (sort) {
            const options = this.parseSortQueryToOptions(sort);
            this.options = Object.assign(this.options, options);
          }

          let query = {};
          if (this.currentPage) query.page = this.currentPage;
          if (this.limit) query.limit = this.limit;
          if (this.filterQuery)
            query.filter = this.$urlEncode(JSON.stringify(this.filterQuery));
          if (this.searchIndex) query.index = this.searchIndex;
          if (
            this.options.sortBy?.length > 0 &&
            this.options.sortDesc?.length > 0
          )
            query.sort = sort;

          //update the URL query
          await this.$router.replace({ ...this.$route, query });
        }

        if (indexChange) {
          //a change of showPriceData triggers a reload of the products
          this.showPriceData = this.searchIndex === "price";
        } else if (
          (!this.isMainComponent ||
            namedRoute === "products" ||
            (namedRoute === "productDetail" &&
              (!this.selectedProduct || pageChange))) &&
          !filterChange
        ) {
          //load products either if the table page has changed or the product is requested directly via URL
          //if a filter is set as query parameter, do not load it here, because the products are already reloaded when changing the filter
          await this.loadProducts();
        }

        if (namedRoute === "productDetail") {
          const sku = this.$route.params.product;
          if (!sku || this.searchIndex === "price") {
            //if there is no SKU or the searched index is the price index, prevent opening the product detail
            this.closeProductDetail();
            return;
          }

          if (
            !this.selectedProduct ||
            sku !== this.selectedProduct._source.sku
          ) {
            //show product detail of requested product
            //load product and open product detail, if the product was accessed via URL
            const product = await this.loadProductBySku(sku);
            if (!product) {
              this.$store.commit(
                "SET_ERROR",
                "Error: Product with SKU " + sku + " could not be found"
              );
              this.closeProductDetail();
              return;
            }

            this.openProductDetail(product);
          }
        }
      }
    },

    async loadProducts() {
      let aborted = false;
      try {
        if (this.runningAction && this.loadProductsController) {
          //Abort the already running request before starting a new one
          this.loadProductsController.abort();
        }

        this.runningAction = true;
        //build the ES search query from the given limit, filters, etc.
        const offset =
          this.limit * (this.currentPage > 0 ? this.currentPage - 1 : 0);
        let search = this.searchRequestBody({
          limit: this.limit,
          offset,
          query: this.filterQuery,
        });

        //add sorting parameters
        const sortBy = this.options?.sortBy;
        const sortDesc = this.options?.sortDesc;
        if (Array.isArray(sortBy)) {
          for (let i = 0; i < sortBy.length; i++) {
            let property = sortBy[i].replace("_source.", "");
            const filter = this.possibleFilters.find(
              (filter) => filter.property === property
            );
            if (!filter) continue;
            //add .keyword to property key to avoid problems with text fields in elastic search
            if (filter.type === "text") property += ".keyword";
            search.sort.push({
              [property]: sortDesc[i] ? "desc" : "asc",
            });
          }
        }

        //Create new abort controller to enable to possibility of aborting requests
        //Requests will be aborted, if another load request is called, while the current one is
        //still running
        const controller = new AbortController();
        const signal = controller.signal;
        this.loadProductsController = controller;

        let params = {};
        if (this.catalog) {
          params = {
            catalogId: this.catalog.id,
            catalogVersion: this.catalog.catalogVersion,
            indexVersion: this.catalog.indexVersion,
          };
        }

        const searchResult =
          await this.$store.$coreApi.coreElasticSearchApi.search({
            domain: this.selectedDomain,
            indexType: this.searchIndex,
            body: search,
            signal,
            ...params,
          });

        if (searchResult) {
          if (searchResult.aborted) {
            aborted = true;
            this.loadProductsController = null;
            return;
          }
          this.total = searchResult.total;
          this.products = searchResult.items;
        }
      } finally {
        if (!aborted) this.runningAction = false;
      }
    },

    async loadProductBySku(sku) {
      //get default body
      let query = this.searchRequestBody({ limit: 10000 });

      //create a nested boolean query,
      //because the result should match the given SKU
      const nestedBoolean = {
        bool: {
          should: [{ match: { "sku.keyword": sku } }],
        },
      };

      query.query.bool.must.push(nestedBoolean);
      const result = await this.$store.$coreApi.coreElasticSearchApi.search({
        domain: this.selectedDomain,
        indexType: "product",
        body: query,
      });

      if (!result) return null;

      return result.items[0];
    },

    openProductDetail(product) {
      if (!this.showPriceData) {
        const sku = product._source.sku;
        //open the product detail in new browser tab if parameter is set and the current route is not named route 'products' or one of its children
        //also set the filter to the products SKU
        if (!this.isMainComponent) {
          if (this.openDetailsInTab) {
            const filter = this.$urlEncode(
              JSON.stringify(this.skuFilterQuery(sku))
            );
            const routeData = this.$router.resolve({
              name: "productDetail",
              params: {
                product: sku,
              },
              query: {
                page: this.currentPage,
                limit: this.currentLimit,
                filter,
                index: this.searchIndex,
              },
            });

            window.open(routeData.href, "_blank");
            return;
          } else {
            return;
          }
        }

        this.selectedProduct = product;
        this.$router.replace({
          name: "productDetail",
          params: { product: sku },
          query: this.$route.query,
        });
      }
    },

    closeProductDetail() {
      this.selectedProduct = null;
      this.$router.replace({
        name: "products",
        query: this.$route.query,
      });
    },

    getSortString(options) {
      let sortBy = options?.sortBy;
      let sortDesc = options?.sortDesc;
      let sort = "";
      //build sort url parameter value
      if (Array.isArray(sortBy)) {
        for (let i = 0; i < sortBy.length; i++) {
          let sortQuery = (sortDesc[i] ? "-" : "") + sortBy[i];
          if (!sort) sort = sortQuery;
          else sort += "," + sortQuery;
        }
      }
      return sort;
    },

    togglePriceData() {
      this.filterQuery = null;
      this.options = Object.assign({}, { sortBy: [], sortDesc: [] });
      this.showPriceData = !this.showPriceData;
    },
  },

  computed: {
    index() {
      return this.catalog
        ? this.catalog
        : this.searchIndex + "." + this.selectedDomain;
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    language() {
      return this.$store.state.localization.fallbackLocale;
    },

    totalPages() {
      let total = this.total;
      //only show 10000 items max since elastic search pagination cannot handle more
      if (total > 10000) total = 10000;
      return Math.ceil(total / this.limit);
    },

    pageLimits() {
      return [20, 100, 500];
    },

    headers() {
      let headers = [
        { text: "SKU", value: "_source.sku", sortable: true },
        { text: "Spec-ID", value: "_source.sps", sortable: true },
        {
          text: "Service provider ID",
          value: "_source.serviceProviderId",
          sortable: true,
        },
        { text: "Product Type", value: "_source.productType", sortable: true },
      ];

      if (this.showPriceData) {
        headers = [
          { text: "SKU", value: "_source.sku", sortable: true },
          { text: "Spec-ID", value: "_source.sps", sortable: true },
          {
            text: "Product Type",
            value: "_source.productType",
            sortable: true,
          },
          { text: "From Date", value: "_source.fromDate", sortable: true },
          { text: "Until Date", value: "_source.untilDate", sortable: true },
          {
            text: "Price",
            value: "_source.value",
            sortable: true,
            isPrice: true,
          },
        ];
      }

      if (this.language) {
        const property = "productName." + this.language;
        const text = "Product Name (" + this.language + ")";
        const value = "_source." + property;

        if (!headers.some((header) => header.value === value)) {
          headers.unshift({
            text,
            value,
          });
        }
      }

      return headers;
    },

    possibleFilters() {
      let filters = [
        { text: "SKU", property: "sku", type: "text" },
        { text: "Spec-ID", property: "sps", type: "text" },
        { text: "External ID", property: "externalId", type: "text" },
        {
          text: "Service provider ID",
          property: "serviceProviderId",
          type: "text",
        },
        { text: "Product Type", property: "productType", type: "text" },
      ];

      if (this.showPriceData) {
        filters = [
          { text: "SKU", property: "sku", type: "text" },
          { text: "Spec-ID", property: "sps", type: "text" },
          { text: "Product Type", property: "productType", type: "text" },
          {
            text: "From Date",
            property: "fromDate",
            type: "date",
            dateOnly: true,
          },
          {
            text: "Until Date",
            property: "untilDate",
            type: "date",
            dateOnly: true,
          },
          { text: "Price", property: "value", type: "currency" },
        ];
      }

      if (this.language) {
        const property = "productName." + this.language;
        const text = "Product Name (" + this.language + ")";
        if (!filters.some((header) => header.property === property)) {
          filters.unshift({
            property,
            text,
            type: "text",
          });
        }
      }

      return filters;
    },

    internalSearchProps() {
      this.possibleFilters;
      let props = this.$cloneObject(this.searchProps);
      if (!props?.possibleFilters) {
        this.$set(props, "possibleFilters", this.possibleFilters);
      }
      this.$set(
        props,
        "disableFullTextSearch",
        props?.disableFullTextSearch ?? false
      );

      return props;
    },

    isMainComponent() {
      return this.$route.matched.some(({ name }) => name === "products");
    },
  },
};
</script>

<style scoped>
.product-main-container {
  align-items: flex-start;
  height: 100%;
  padding: 0;
}

.product-table-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding-top: 12px;
}

.product-table-toolbar .extended-toolbar {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 12px 8px 12px;
}

.product-table {
  display: flex;
  overflow-y: scroll;
  align-items: flex-start;
  max-height: 100%;
  padding: 0;
}

.product-table.v-data-table {
  display: flex;
  flex: 1 1 auto;
}

.product-table.v-data-table::v-deep > .v-data-table__wrapper {
  width: 100%;
}

.products-limit-select {
  max-width: 70px;
  margin-right: 5px;
}

.product-table
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.selected {
  background-color: var(--v-psblue-base);
  color: white;
}

/* Prevent wrapping of values in columns */
.product-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(.v-data-table__empty-wrapper) {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-table:not(.price-data).v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr {
  cursor: pointer;
}
</style>
