<template>
  <MasterDetail
    class="catalog-main-container"
    data-test-id="catalogOverview"
    :detail-open="detailOpened"
    @table:resize="tableWidth = $event"
  >
    <template #toolbar>
      <v-container fluid class="catalog-table-toolbar elevation-4">
        <v-toolbar flat>
          <v-toolbar-title>Catalog List</v-toolbar-title>
          <v-divider class="mx-4" vertical></v-divider>
          <v-btn
            @click="loadCatalogs()"
            icon
            class="reload-btn"
            :disabled="runningAction"
            data-test-id="reloadCatalogsBtn"
          >
            <v-icon>mdi-sync</v-icon>
          </v-btn>
          <div class="text-truncate">{{ total }} entries found</div>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            title="Schedules reindex for all catalogs on this domain"
            data-test-id="scheduleReindexAllBtn"
            :disabled="runningAction"
            :icon="showMinified"
            @click="scheduleIndex()"
          >
            <v-icon :left="!showMinified">mdi-update</v-icon>
            <div v-if="!showMinified">Schedule reindex for all</div>
          </v-btn>
          <v-btn
            text
            color="primary"
            data-test-id="triggerSharingBtn"
            title="Starts reindex for all catalogs with state DIRTY"
            :disabled="runningAction"
            :icon="showMinified"
            @click="triggerReindex"
          >
            <v-icon :left="!showMinified">mdi-send-clock</v-icon>
            <div v-if="!showMinified">Reindex dirty now</div>
          </v-btn>
          <v-btn
            text
            color="primary"
            class="relation-graph-btn"
            data-test-id="showCatalogRelBtn"
            title="Open catalog relationship graph"
            :icon="showMinified"
            :disabled="runningAction || showRelations"
            @click="showRelationsGraph"
          >
            <v-icon :left="!showMinified">mdi-graph</v-icon>
            <div v-if="!showMinified">Show relationship graph</div>
          </v-btn>
          <v-btn
            color="primary"
            class="create-catalog-btn"
            data-test-id="newCatalogBtn"
            title="Create new catalog"
            :disabled="runningAction"
            :fab="showMinified"
            :small="showMinified"
            @click="openCatalogDialog"
          >
            <v-icon v-if="showMinified">mdi-plus</v-icon>
            <div v-else>New Catalog</div>
          </v-btn>
        </v-toolbar>
      </v-container>
      <v-dialog v-model="showCreateDialog" persistent max-width="600px">
        <Catalog
          :catalogs="catalogs"
          :key="Math.random()"
          @close="showCreateDialog = false"
          @create="createCatalog"
          data-test-id="createCatalogDialog"
        />
      </v-dialog>
    </template>

    <template #table>
      <v-data-table
        hide-default-footer
        sort-by="id"
        disable-pagination
        single-select
        class="catalog-table"
        fixed-header
        multi-sort
        :items="catalogs"
        :headers="headers"
        :loading="runningAction"
        :items-per-page="-1"
        :height="tableHeight"
        :options.sync="options"
      >
        <!-- TABLE ROW -->
        <template v-slot:item="{ item }">
          <tr
            @click="!runningAction && openCatalogDetail(item)"
            :class="{
              selected: selectedCatalog && item.id == selectedCatalog.id,
            }"
            :data-test-id="'catalog_' + item.id"
          >
            <td :data-test-id="'catalog_' + item.id + '_name'">
              {{ item.name }}
            </td>
            <td :data-test-id="'catalog_' + item.id + '_type'">
              {{ item.type }}
            </td>
            <td>
              <v-icon
                :color="item.published ? 'green' : 'red'"
                v-text="
                  item.published
                    ? 'mdi-check-circle-outline'
                    : 'mdi-close-circle-outline'
                "
                dark
              />
            </td>
            <td>
              <StatusChip
                :status="item.cleanState ? item.cleanState : 'ERROR: NO STATE!'"
              />
            </td>
            <td :data-test-id="'catalog_' + item.id + '_mark_dirty_at'">
              {{ item.markDirtyAt ? $getLocalizedDate(item.markDirtyAt) : "-" }}
            </td>
            <td v-if="!detailOpened">
              <v-btn
                outlined
                small
                title="Schedules reindex for this catalog as soon as possible"
                :disabled="runningAction"
                :data-test-id="'catalog_' + item.id + '_schedule_index_btn'"
                @click.stop.prevent="scheduleIndex(item)"
              >
                <v-icon left>mdi-update</v-icon>
                Schedule reindex
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>

    <template #detail>
      <!-- RELATIONSHIP GRAPH -->
      <CatalogGraph
        v-if="showRelations && !selectedCatalog"
        data-test-id="catalogRelGraph"
        :catalogs="catalogs"
        :style="{
          height: detailHeight + 'px',
        }"
        @close="showRelations = false"
      />
      <!-- CATALOG DETAIL -->
      <CatalogDetail
        v-if="selectedCatalog"
        v-model="selectedCatalog"
        data-test-id="catalogDetail"
        :key="selectedCatalog.id + reloadKey"
        :style="{
          height: detailHeight + 'px',
        }"
        @close="closeCatalogDetail"
        @save="updateCatalogs(false)"
        @delete="updateCatalogs(true)"
      />
    </template>
  </MasterDetail>
</template>

<script>
import MasterDetail from "../common/templates/MasterDetail";
import CatalogGraph from "./graph/CatalogGraph";
import CatalogDetail from "./CatalogDetail";
import Catalog from "./Catalog";
import StatusChip from "../common/display-helpers/StatusChip";
import mainOverviewMixin from "../../mixins/main-overview-mixin";

export default {
  mixins: [mainOverviewMixin],

  provide() {
    return {
      getDetailHeight: () => {
        return this.detailHeight;
      },
    };
  },

  components: {
    MasterDetail,
    CatalogGraph,
    CatalogDetail,
    Catalog,
    StatusChip,
  },

  data() {
    return {
      catalogs: [],
      selectedCatalog: null,
      runningAction: false,
      showRelations: false,
      showCreateDialog: false,
      total: 0,
      reloadKey: null,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
      tableWidth: 0,
    };
  },

  mounted() {
    this.$nextTick(() => this.init());
  },

  watch: {
    "$route.params": function (newParams, oldParams) {
      if (this.$route.matched.some((route) => route.name === "catalogs")) {
        const hasChanged = !this.$isEqual(newParams, oldParams);
        const domainChange = !this.$isEqual(
          newParams?.domain,
          oldParams?.domain
        );
        //make sure the catalog detail gets opened, when the catalog is
        //visited through a link on the current domain (e.g. link in relationship graph)
        if (hasChanged && !domainChange) {
          this.selectedCatalog = null;
          this.init(true);
        }
      }
    },

    options(options) {
      let query = Object.assign({}, this.$route.query);
      const sortBy = options.sortBy;
      const sortDesc = options.sortDesc;
      let sort = "";

      //build sort url parameter value
      if (Array.isArray(sortBy)) {
        for (let i = 0; i < sortBy.length; i++) {
          const sortQuery = (sortDesc[i] ? "-" : "") + sortBy[i];
          if (!sort) sort = sortQuery;
          else sort += "," + sortQuery;
        }
      }

      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);

      this.$router.replace({
        ...this.$route,
        query,
      });
    },
  },

  methods: {
    async init(preventReload) {
      const routeDomain = this.$route.params.domain;
      //A difference between the current and route domain indicates a
      //domain change, so do not load the catalogs now, as this method will get
      //called when the domain is changed.
      if (routeDomain && this.selectedDomain !== routeDomain) return;
      const namedRoute = this.$route.name;
      const routeQuery = this.$route.query;
      let sort = routeQuery.sort;

      if (sort) {
        const sortBy = [];
        const sortDesc = [];
        const sortQuery = sort.split(",");

        const addToSorted = (sortString) => {
          //this function parses the given string and adds the
          //sorting information to the respective arrays
          const isDesc = sortString.startsWith("-");
          const property = isDesc ? sortString.substring(1) : sortString;
          const isSortableField = this.headers.some(
            (header) => header.value === property && header.sortable !== false
          );
          if (isSortableField) {
            sortBy.push(property);
            sortDesc.push(isDesc);
          }
        };

        if (Array.isArray(sortQuery))
          sortQuery.forEach((part) => addToSorted(part));
        else addToSorted(sortQuery);
        this.options = Object.assign(this.options, { sortBy, sortDesc });
      }

      if (
        !preventReload &&
        (namedRoute === "catalogs" ||
          (namedRoute === "catalogDetail" && !this.selectedCatalog))
      ) {
        //load catalogs if the route is correct or the catalog detail is accessed via URL
        await this.loadCatalogs();
      }

      if (namedRoute === "catalogDetail") {
        //show catalog detail of requested catalog
        const catalogId = this.$route.params.catalog;

        if (!catalogId) {
          this.closeCatalogDetail();
        } else if (
          !this.selectedCatalog ||
          catalogId != this.selectedCatalog.id
        ) {
          const catalog = this.catalogs.find(({ id }) => id === catalogId);

          if (!catalog) {
            this.$store.commit(
              "SET_ERROR",
              "Error: Catalog with id " + catalogId + " could not be found"
            );
            this.closeCatalogDetail();
            return;
          }

          this.openCatalogDetail(catalog);
        }
      } else if (namedRoute === "catalogs" && this.selectedCatalog) {
        this.$router.replace({
          name: "catalogDetail",
          params: {
            catalog: this.selectedCatalog.id,
          },
        });
      }
    },

    async loadCatalogs() {
      this.runningAction = true;
      try {
        this.catalogs = [];
        this.catalogs = await this.$store.$coreApi.coreCatalogApi.getCatalogs(
          this.selectedDomain
        );
        this.total = this.catalogs.length;
        this.reloadKey = Math.random();
      } finally {
        this.runningAction = false;
      }
    },

    async openCatalogDetail(catalog) {
      await this.$router.push({
        name: "catalogDetail",
        params: { catalog: catalog.id },
        query: this.$route.query,
      });
      this.showRelations = false;
      this.selectedCatalog = catalog;
    },

    async closeCatalogDetail(reload) {
      await this.$router.push({ name: "catalogs", query: this.$route.query });
      this.selectedCatalog = null;
      if (reload) this.loadCatalogs();
    },

    async openCatalogDialog() {
      await this.closeCatalogDetail();
      this.showCreateDialog = true;
    },

    async createCatalog(catalog) {
      this.showCreateDialog = false;
      await this.loadCatalogs();
      await this.openCatalogDetail(catalog);
    },

    async updateCatalogs(deleted) {
      if (deleted) {
        this.$nextTick(() => this.closeCatalogDetail(true));
        return;
      }
      await this.loadCatalogs();
      this.selectedCatalog = this.catalogs.find(
        ({ id }) => this.selectedCatalog.id === id
      );
    },

    async triggerReindex() {
      this.runningAction = true;
      try {
        await this.$store.$coreApi.coreAdminApi.runCatalogWorker({
          successMsg:
            "Triggered reindex for dirty catalogs on domain " +
            this.selectedDomain,
        });
      } finally {
        this.runningAction = false;
      }
      await this.loadCatalogs();
    },

    async scheduleIndex(catalog) {
      try {
        this.runningAction = true;

        const successMsg =
          "Scheduled indexing" +
          (catalog
            ? " of " + catalog.name
            : " for all catalogs on domain " + this.selectedDomain);

        await this.$store.$coreApi.coreCatalogApi.scheduleIndex(
          this.selectedDomain,
          "asap",
          catalog?.id,
          { successMsg }
        );
      } finally {
        this.runningAction = false;
      }
      await this.loadCatalogs();
    },

    async showRelationsGraph() {
      await this.closeCatalogDetail();
      this.showRelations = true;
    },
  },

  computed: {
    headers() {
      const headers = [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Published", value: "published" },
        { text: "Status", value: "cleanState" },
        { text: "Mark DIRTY at", value: "markDirtyAt" },
      ];

      if (!this.detailOpened) {
        headers.push({ text: "", value: "actions", sortable: false });
      }
      return headers;
    },

    detailOpened() {
      return !!this.selectedCatalog || this.showRelations;
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    showMinified() {
      return (
        this.getPageWidth() <= 1366 ||
        (this.selectedCatalog && this.tableWidth <= 1366)
      );
    },
  },
};
</script>  

<style scoped>
.container {
  margin: 0;
  padding: 0;
}

.catalog-main-container {
  flex-flow: column nowrap;
  background-color: white;
  align-items: flex-start;
  height: 100%;
  padding: 0;
}

.catalog-detail-container,
.catalog-graph-container {
  align-items: flex-start;
  border: 1px solid var(--v-psblue-base);
  max-width: 80%;
  width: 100%;
}

.catalog-table-container {
  display: flex;
  padding: 12px 0px 0px 0px;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.catalog-graph-container,
.catalog-table-container,
.catalog-detail-container {
  height: calc(100% - 64px);
}

.catalog-graph-container {
  background-color: white;
}

.catalog-table {
  height: 100%;
  overflow-y: scroll;
  flex: 1 1 auto;
}

.catalog-table-toolbar .v-btn + .v-btn {
  margin-left: 0.5rem;
}

.catalog-table-container
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(.v-data-table__empty-wrapper) {
  text-align: left;
  cursor: pointer;
}
</style>