<template>
  <v-card flat class="catalog-graph-detail" data-test-id="catalogGraphMenu">
    <v-toolbar flat>
      <v-toolbar-title :title="title">
        <a
          v-if="isCatalog"
          :href="getCatalogLink(catalog.domain, catalog.id)"
          @click.stop.prevent="openCatalogDetail(catalog.domain, catalog.id)"
          data-test-id="graph_detail_title"
        >
          {{ title }}
        </a>
        <span v-else data-test-id="graph_detail_title">{{ title }}</span>
      </v-toolbar-title>
      <v-divider vertical inset class="mx-4"></v-divider>
      <div :title="type">{{ type }}</div>
      <v-spacer />
      <v-btn
        icon
        class="ml-2"
        data-test-id="catalogGraphMenuCloseBtn"
        @click="closeDetail"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider class="mx-4" />
    <div v-if="isRule" class="graph-node-properties">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title data-test-id="rule_id">{{
              rule.id
            }}</v-list-item-title>
            <v-list-item-subtitle>ID</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title data-test-id="rule_type">{{
              type
            }}</v-list-item-title>
            <v-list-item-subtitle>Type</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <a
                :href="getCatalogLink(selectedDomain, rule.srcCatalog)"
                @click.stop.prevent="
                  openCatalogDetail(selectedDomain, rule.srcCatalog)
                "
                data-test-id="rule_source_catalog"
              >
                {{ rule.srcCatalogName || rule.srcCatalog }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>Source Catalog</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <a
                :href="
                  getCatalogLink(
                    hasFilter ? selectedDomain : rule.catalogDomain,
                    rule.catalog
                  )
                "
                @click.stop.prevent="
                  openCatalogDetail(
                    hasFilter ? selectedDomain : rule.catalogDomain,
                    rule.catalog
                  )
                "
                data-test-id="rule_target_catalog"
              >
                {{ rule.catalogName || rule.catalog }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>Target Catalog</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!hasFilter">
          <v-list-item-content>
            <v-list-item-title data-test-id="rule_target_catalog_domain">{{
              rule.catalogDomain
            }}</v-list-item-title>
            <v-list-item-subtitle>Target Catalog Domain</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-textarea
        v-if="hasFilter"
        readonly
        outlined
        label="Filter"
        hide-details
        data-test-id="rule_filter"
        :value="formatRuleFilter(graphNode.rule.filter)"
      />
    </div>
    <div v-else class="graph-node-properties">
      <v-list v-if="isCatalog">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title :data-test-id="'catalog_id'">
              {{ catalog.id }}
            </v-list-item-title>
            <v-list-item-subtitle>ID</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title :data-test-id="'catalog_name'">
              {{ catalog.name }}
            </v-list-item-title>
            <v-list-item-subtitle>Name</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title :data-test-id="'catalog_type'">
              {{ catalog.type }}
            </v-list-item-title>
            <v-list-item-subtitle>Type</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title :data-test-id="'catalog_domain'">
              {{ catalog.domain }}
            </v-list-item-title>
            <v-list-item-subtitle>Domain</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title :data-test-id="'catalog_cleanState'">
              <StatusChip
                :status="
                  catalog.cleanState ? catalog.cleanState : 'ERROR: NO STATE!'
                "
              />
            </v-list-item-title>
            <v-list-item-subtitle>Status</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-subheader v-else>No information available</v-subheader>
    </div>
  </v-card>
</template>

<script>
import StatusChip from "../../common/display-helpers/StatusChip";
export default {
  components: {
    StatusChip,
  },

  props: {
    //can be either catalog or rule
    graphNode: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formatRuleFilter(filter) {
      return JSON.stringify(JSON.parse(filter), undefined, 4);
    },

    closeDetail() {
      this.$emit("close");
    },

    getCatalogRoute(domain, catalog) {
      return {
        name: "catalogDetail",
        params: {
          catalog,
          domain,
          keepParams: true,
        },
      };
    },

    getCatalogLink(domain, catalog) {
      const route = this.getCatalogRoute(domain, catalog);
      return this.$router.resolve(route).href;
    },

    async openCatalogDetail(domain, catalog) {
      const route = this.getCatalogRoute(domain, catalog);
      return this.$router.push(route);
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    isRule() {
      return !!this.graphNode.rule;
    },

    rule() {
      return this.graphNode?.rule;
    },

    hasFilter() {
      return !!this.rule?.filter;
    },

    catalog() {
      return this.graphNode?.catalog;
    },

    isCatalog() {
      return !!this.catalog;
    },

    ruleProperties() {
      //only needed to remove the filter property from the rule
      //because it is is displayed in another way
      if (!this.graphNode.rule) return [];
      return Object.keys(this.graphNode.rule).filter(
        (property) => property !== "filter"
      );
    },

    title() {
      if (this.isRule) return this.graphNode.id;
      const catalog = this.graphNode?.catalog;
      if (catalog) return catalog?.name ?? catalog?.id;
      return this.graphNode?.name ?? this.graphNode?.id;
    },

    type() {
      if (this.isRule) {
        return this.graphNode.rule.filter ? "FILTER RULE" : "SHARING RULE";
      }

      return this.graphNode.catalog ? "CATALOG" : "EXTERNAL CATALOG";
    },
  },
};
</script>

<style scoped>
.catalog-graph-detail {
  text-align: left;
  padding: 8px;
  width: 400px;
  height: 100%;
}

.catalog-graph-detail .graph-node-properties {
  height: calc(100% - 64px);
  overflow-y: scroll;
}
</style>