<template>
    <div class="status-chip">
        <v-chip
            v-if="!showProgress"
            :class="{
                'justify-center': true,
                'clickable': clickable && !loading
            }"
            :color="color"
            :dark="color !== stateColor.PENDING"
            :label="label"
            :disabled="loading || disabled"
            :small="small"
            @click="$emit('click', $event)"
        >
            <div v-if="!loading" class="d-flex align-center">
                {{ content }}
                <v-icon 
                    v-if="appendIcon" 
                    small right
                >
                    {{appendIcon}}
                </v-icon>
            </div>
            <div v-else class="px-4">
                <v-progress-circular 
                    indeterminate
                    :size="small ? 16 : 24"
                    :width="small ? 2 : 4"
                    color="grey darken-3"
                />
            </div>
        </v-chip>
        <div 
            v-else 
            v-ripple="clickable"
            :class="{
                'progress-bar': true,
                'clickable': clickable && !loading
            }" 
            :style="{
                width: progressBarWidth + 'px',
            }"
            data-test-id="progressBar"
            @click="$emit('click', $event)"
        >
            <v-progress-linear 
                :value="progressValue"
                :class="{
                    small: small
                }"
                :color="!loading ? color : 'grey'"
                :height="small ? 24 : 32"
                rounded
                class="v-chip v-size--default"
            >
                <div class="d-flex flex-row align-center">
                    <div 
                        ref="progressText"
                        :class="{
                            'white--text':  color !== stateColor.PENDING,
                            'black--text':  color === stateColor.PENDING  
                        }"
                    >
                        {{progressText}}
                    </div>
                   <v-icon 
                        v-if="appendIcon" 
                        small right
                    >
                        {{appendIcon}}
                    </v-icon>
                </div>

            </v-progress-linear>
        </div>
    </div>
</template>

<script>

export default {
    
    props: {

        //the status which should be displayed
        status: {
            type: String,
            required: false
        },

        //will be shown instead of the status
        text: {
            type: String,
            required: false
        },

        //if true, changes the shape of the chip from pill to label box
        label: {
            type: Boolean,
            required: false,
            default: false
        },

        //if true, the chip will be in a loading state which means
        //the chip is disabled and shows a loading indicator
        loading: {
            type: Boolean,
            required: false,
            default: false
        },


        //if true, the chip willbe greyed out
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        //specifies an item which should be appended to the status chip 
        appendIcon: {
            type: String,
            required: false
        },

        //if true, sets the chips height to 24px
        small: {
            type: Boolean,
            required: false,
            default: false
        },

        //if this value is set and between 1 and 99
        // a progress bar will be shown instead of the chip
        progress: {
            type: Number,
            required: false,
        }

    },

    data(){
        return {
            progressBarWidth: 140
        }
    },

    mounted(){
        this.calculateWidth();
    },

    watch: {
        progressText(){
            this.calculateWidth();
        },
    },

    methods: {
        calculateWidth(){
            this.$nextTick(() => {
                const element = this.$refs?.progressText;
                if(element){
                    //length of text + 12px padding left and rigth
                    this.progressBarWidth = element.offsetWidth + 24 + (this.appendIcon ? 28 : 0);
                }
            })
        }
    },

    computed: {

        clickable(){
            //check if a click listener is registered
            return !!this.$listeners?.click;
        },

        content(){
            if(this.text) return this.text;
            return this.status?.toUpperCase()?.replace("_", " ");
        },

        progressValue(){
            let progress = this.progress;
            if(this.$getType(this.progress) !== "number") progress = Number(progress);
            if(Number.isNaN(progress)) return undefined;
            return Math.floor(progress);
        },

        showProgress(){
            return !this.loading && this.progressValue && this.progressValue > 0 && this.progressValue < 100;
        },

        progressText(){
            return this.content + " " + this.progressValue + "%"
        },

        stateColor(){
            return {
                SUCCESS: "green",
                INIT: "blue",
                PENDING: "amber",
                ERROR: "red",
                LOADING: "grey lighten-1",
                UNDEFINED: "grey"
            }
        },

        color(){
            if(this.loading) return this.stateColor.LOADING;
            //return the correct color for each status
            switch(this.status){

                case 'NEW': 
                case 'PRELOAD_NEW': 
                case 'INIT':
                    return this.stateColor.INIT;

                case 'COMPLETE':
                case 'PRELOAD_COMPLETE':
                case 'CLEAN':
                case 'SUCCESS':
                case 'PRODUCED':
                    return this.stateColor.SUCCESS;
                
                case 'CONFIRMED':
                case 'PENDING':
                case 'PENDING_ACK':
                case 'PRELOAD_PENDING':
                case 'CLEAN_PENDING':
                case 'RUNNING':
                    return this.stateColor.PENDING;

                case 'ERROR':
                case 'CANCEL':
                case 'CANCELED':
                case 'CANCELLED':
                case 'NOT_CANCELABLE':
                case 'USED':
                case 'REJECT':
                case 'FAILED':
                case 'PRELOAD_FAILED':
                case 'DISCARDED':
                case 'DIRTY':
                    return this.stateColor.ERROR;

                default:
                    return this.stateColor.UNDEFINED;
            }
        },
    }
}
</script>

<style scoped>

.status-chip{
    display: flex;
    justify-content: flex-start;
}

.status-chip > .progress-bar{
    display: flex;
    flex-direction: row;
    min-width: 140px;
    flex: 0 1 100%;
    align-items: center;
}

.status-chip > .progress-bar > .v-progress-linear.small{
    font-size: 12px;
}

.status-chip > .progress-bar.clickable > .v-progress-linear{
    cursor: pointer;
}

.status-chip > .progress-bar.clickable > .v-progress-linear::v-deep > .v-progress-linear__content > div{
    height: 100%;
}

.status-chip > .v-chip:not(.clickable){
    pointer-events: none;
}

.status-chip > .progress-indicator{
    margin-left: 4px;
}

.status-chip > .progress-indicator::v-deep > .v-progress-circular__info{
    font-size: 12px;
    font-weight: bold;
}

</style>