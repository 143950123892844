var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DetailView',{attrs:{"title":"Catalog relationship graph","loading":_vm.runningAction},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var loading = ref.loading;
return [_c('v-container',{staticClass:"graph-container",style:({
        height: _vm.graphHeight + 'px',
      }),attrs:{"id":"catalogGraphContainer","fluid":""}},[_c('v-menu',{attrs:{"absolute":"","top":"","right":"","attach":"#catalogGraphContainer","content-class":"graph-property-menu","close-on-click":false,"close-on-content-click":false},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('CatalogGraphDetail',{attrs:{"graph-node":_vm.selectedObject},on:{"close":function($event){_vm.showMenu = false}}})],1),_c('v-menu',{attrs:{"top":"","offset-y":"","content-class":"graph-legend-menu","close-on-click":false,"close-on-content-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"graph-legend-btn",attrs:{"fab":"","absolute":"","bottom":"","left":"","small":"","color":"primary","dark":"","data-test-id":"catalogGraphLegendBtn","disabled":loading}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-map-legend")])],1)]}}],null,true)},[_c('v-list',{staticClass:"graph-legend-menu-list",attrs:{"data-test-id":"catalogGraphLegendMenu"}},[_c('v-subheader',[_vm._v("Catalogs")]),_vm._l(([
              'import',
              'filter',
              'manual',
              'external' ]),function(type,index){
            var _obj;
return _c('v-list-item',{key:type + index},[_c('v-list-item-icon',[_c('div',{class:( _obj = { dot: true }, _obj[type] = true, _obj )})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(type.toUpperCase()))])],1)],1)}),_c('v-subheader',[_vm._v("Rules")]),_vm._l((['filter', 'sharing']),function(rule){
            var _obj;
return _c('v-list-item',{key:rule},[_c('v-list-item-icon',[_c('div',{class:( _obj = { line: true }, _obj[rule] = true, _obj )})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(rule.toUpperCase()))])],1)],1)})],2)],1),(!loading)?_c('D3Network',{attrs:{"net-nodes":_vm.nodes,"net-links":_vm.links,"options":_vm.options,"node-cb":_vm.createNode},on:{"node-click":_vm.showProperties,"link-click":_vm.showProperties}}):_vm._e(),_c('svg',{staticClass:"arrow-marker-template"},[_c('defs',[_c('marker',{attrs:{"id":"arrow","markerWidth":"6","markerHeight":"5","refX":"10.5","refY":"3.5","orient":"auto"}},[_c('polygon',{attrs:{"points":"0 2, 6 3.5, 0 5"}})])])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }