export default {
    methods: {

        parseSortQueryToOptions(sortString) {
            const sortBy = [];
            const sortDesc = [];
            const sortQuery = sortString.split(",");

            const addToSorted = (sortString) => {
                //this function parses the given string and adds the
                //sorting information to the respective arrays
                const isDesc = sortString.startsWith("-");
                const property = isDesc ? sortString.substring(1) : sortString;
                const isSortableField = this.headers.some(
                    (header) =>
                        header.value === property && header.sortable !== false
                );
                if (isSortableField) {
                    sortBy.push(property);
                    sortDesc.push(isDesc);
                }
            };

            if (Array.isArray(sortQuery))
                sortQuery.forEach((part) => addToSorted(part));
            else addToSorted(sortQuery);
            return { sortBy, sortDesc };
        },

        parseOptionsToSortQuery(options) {
            const sortBy = options.sortBy;
            const sortDesc = options.sortDesc;
            let sort = "";

            //build sort url parameter value
            if (Array.isArray(sortBy)) {
                for (let i = 0; i < sortBy.length; i++) {
                    const sortQuery = (sortDesc[i] ? "-" : "") + sortBy[i];
                    if (!sort) sort = sortQuery;
                    else sort += "," + sortQuery;
                }
            }

            return sort;
        }
    }
}