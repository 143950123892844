<template>
  <DetailView
    title="Catalog relationship graph"
    :loading="runningAction"
    @close="$emit('close')"
  >
    <template #content="{ loading }">
      <v-container
        id="catalogGraphContainer"
        fluid
        class="graph-container"
        :style="{
          height: graphHeight + 'px',
        }"
      >
        <!-- MENU FOR LINKS AND NODES -->
        <v-menu
          v-model="showMenu"
          absolute
          top
          right
          attach="#catalogGraphContainer"
          content-class="graph-property-menu"
          :close-on-click="false"
          :close-on-content-click="false"
        >
          <CatalogGraphDetail
            :graph-node="selectedObject"
            @close="showMenu = false"
          />
        </v-menu>

        <v-menu
          top
          offset-y
          content-class="graph-legend-menu"
          :close-on-click="false"
          :close-on-content-click="false"
          open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              absolute
              bottom
              left
              small
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="graph-legend-btn"
              data-test-id="catalogGraphLegendBtn"
              :disabled="loading"
            >
              <v-icon>mdi-map-legend</v-icon>
            </v-btn>
          </template>

          <v-list
            class="graph-legend-menu-list"
            data-test-id="catalogGraphLegendMenu"
          >
            <v-subheader>Catalogs</v-subheader>
            <v-list-item
              v-for="(type, index) in [
                'import',
                'filter',
                'manual',
                'external',
              ]"
              :key="type + index"
            >
              <v-list-item-icon>
                <div :class="{ dot: true, [type]: true }" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ type.toUpperCase() }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>Rules</v-subheader>
            <v-list-item v-for="rule in ['filter', 'sharing']" :key="rule">
              <v-list-item-icon>
                <div :class="{ line: true, [rule]: true }" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ rule.toUpperCase() }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <D3Network
          v-if="!loading"
          :net-nodes="nodes"
          :net-links="links"
          :options="options"
          :node-cb="createNode"
          @node-click="showProperties"
          @link-click="showProperties"
        />

        <svg class="arrow-marker-template">
          <defs>
            <marker
              id="arrow"
              markerWidth="6"
              markerHeight="5"
              refX="10.5"
              refY="3.5"
              orient="auto"
            >
              <polygon points="0 2, 6 3.5, 0 5"></polygon>
            </marker>
          </defs>
        </svg>
      </v-container>
    </template>
  </DetailView>
</template>

<script>
import DetailView from "../../common/templates/DetailView";
import CatalogGraphDetail from "./CatalogGraphDetail";
import D3Network from "vue-d3-network";
import mixin from "../../../mixins/catalog-rules-mixin";

export default {
  inject: ["getDetailHeight"],

  mixins: [mixin],

  components: {
    DetailView,
    D3Network,
    CatalogGraphDetail,
  },

  props: {
    catalogs: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showMenu: false,
      nodes: [],
      catalogNodes: {},
      links: [],
      selectedObject: {},
      runningAction: false,
    };
  },

  mounted() {
    this.buildNodesAndLinks();
  },

  watch: {
    catalogs() {
      this.buildNodesAndLinks();
    },
  },

  methods: {
    createNode(node) {
      node.pinned = true;
      return node;
    },

    showProperties(event, object) {
      this.selectedObject = object;
      this.showMenu = true;
    },

    async buildNodesAndLinks() {
      try {
        this.runningAction = true;
        this.catalogNodes = {};
        this.nodes = [];
        this.links = [];

        this.catalogs.forEach((catalog) => {
          this.$set(this.catalogNodes, catalog.id, catalog);
          this.nodes.push({
            id: catalog.id,
            name: catalog.name ? catalog.name : catalog.id,
            catalog,
            _cssClass: catalog.type.toLowerCase(),
          });
        });

        let rules = await this.getCatalogRules();
        if (!rules) rules = [];
        for (let rule of rules) {
          await this.addLink(rule);
        }
      } finally {
        this.runningAction = false;
      }
    },

    async addLink(rule) {
      //Create pseudo catalog node if the source or target catalog does not exist on this domain
      if (!this.catalogNodes[rule.catalog]) {
        let catalog;
        try {
          catalog = await this.$store.$coreApi.coreCatalogApi.getCatalog(
            rule.catalogDomain,
            rule.catalog,
            true
          );

          if (catalog?.ok === false) return;
        } catch (e) {
          console.warn("Catalog " + rule.catalog + " could not be loaded", e);
        }

        if (catalog) {
          const name =
            (catalog.name ?? catalog.id) + " (" + catalog.domain + ")";

          const _cssClass = "external " + catalog.type.toLowerCase();
          this.nodes.push({
            id: catalog.id,
            name,
            catalog,
            _cssClass,
          });

          this.$set(this.catalogNodes, rule.catalog, catalog);
        } else {
          this.nodes.push({
            id: rule.catalog,
            name: rule.catalog,
            _cssClass: "external",
          });
        }
      } else if (!this.catalogNodes[rule.srcCatalog]) {
        this.nodes.push({
          id: rule.srcCatalog,
          name: rule.srcCatalog,
          _cssClass: "external",
        });
      }
      const catalogName = this.catalogNodes?.[rule.catalog]?.name;
      const srcCatalogName = this.catalogNodes?.[rule.srcCatalog]?.name;
      this.$set(rule, "catalogName", catalogName);
      this.$set(rule, "srcCatalogName", srcCatalogName);

      this.links.push({
        id: rule.id,
        sid: rule.srcCatalog,
        tid: rule.catalog,
        _svgAttrs: {
          "marker-end": "url(#arrow)",
          "stroke-dasharray": rule.filter ? 0 : 10,
        },
        rule,
      });
    },
  },

  computed: {
    options() {
      return {
        force: 6000, //min. space between nodes
        nodeSize: 25,
        nodeLabels: true, //show lables of nodes
        linkLabels: false, //hide link labels
        canvas: false, //draw in svg mode
        strLinks: true, //draw straight links
      };
    },

    graphHeight() {
      return this.getDetailHeight() - 64 - 12;
    },
  },
};
</script>

<style src="vue-d3-network/dist/vue-d3-network.css"></style>
<style scoped>
.graph-container {
  position: relative;
  padding: 0;
}

.graph-container .arrow-marker-template {
  position: absolute;
  top: 0;
  left: 0;
}

.graph-container::v-deep .net,
.net-svg {
  height: 100%;
  width: 100%;
}

.graph-container::v-deep .node {
  stroke-width: 0px;
}

.graph-container::v-deep .node.filter,
.graph-legend-menu-list .v-list-item__icon > .dot.filter {
  fill: var(--v-psgreen-base);
  background-color: var(--v-psgreen-base);
}

.graph-container::v-deep .node.import,
.graph-legend-menu-list .v-list-item__icon > .dot.import {
  fill: var(--v-psblue-base);
  background-color: var(--v-psblue-base);
}

.graph-container::v-deep .node.manual,
.graph-legend-menu-list .v-list-item__icon > .dot.manual {
  fill: var(--v-slpink-base);
  background-color: var(--v-slpink-base);
}

.graph-container::v-deep .node.external,
.graph-legend-menu-list .v-list-item__icon > .dot.external {
  stroke: black;
  stroke-width: 3px;
  stroke-dasharray: 8;
  border-style: dashed;
  border-color: black;
}

.graph-container::v-deep .link {
  stroke: black;
  stroke-width: 2px;
}

.graph-container::v-deep .node-label {
  fill: black;
  font-size: 12px;
  font-weight: bold;
}

.graph-container::v-deep .link:hover {
  stroke: green;
}

.graph-container::v-deep .node:hover {
  stroke: green;
  stroke-width: 3px;
}

.graph-container {
  overflow-y: hidden;
  height: calc(100% - 64px);
}

.graph-container > .graph-legend-btn {
  bottom: 16px !important;
}

/* Node property menu */
.graph-container > .graph-property-menu.v-menu__content {
  left: auto !important;
  top: 0px !important;
  right: 0px !important;
  margin: 12px;
  height: calc(100% - 24px);
  background-color: white;
}

.graph-container > .graph-legend-menu.v-menu__content {
  right: auto !important;
  top: auto !important;
  bottom: 0px !important;
  left: 0px !important;
  margin: 12px;
}

.graph-legend-menu-list {
  height: 100%;
  padding: 12px;
  background-color: white;
  text-align: left;
  height: fit-content;
}

.graph-legend-menu-list .v-list-item__icon > .dot {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-right: 8px;
}

.graph-legend-menu-list .v-list-item__icon > .line {
  border: 2px solid black;
  height: 2px;
  width: 100%;
}

.graph-legend-menu-list .v-list-item__icon > .line.sharing {
  border: 2px dashed black;
}
</style>