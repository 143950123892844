<template>
  <div class="common-calendar">
    <v-toolbar flat>
      <v-btn small outlined class="mr-2" :disabled="loading" @click="showToday">
        Today
      </v-btn>
      <v-btn small icon :disabled="loading" @click="prev">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>
      <v-btn small icon :disabled="loading" @click="next">
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar" class="px-2">{{
        $refs.calendar.title
      }}</v-toolbar-title>
      <v-spacer />

      <div class="d-flex flex-grow-1 ml-3">
        <slot name="actions" :loading="loading" :events="events" />
      </div>
      <div v-if="loading" class="px-4">
        <v-progress-circular
          indeterminate
          :size="24"
          :width="2"
          color="grey darken-3"
        />
      </div>
    </v-toolbar>
    <div class="calendar-body">
      <slot name="hint" :loading="loading" :events="events" />
      <v-calendar
        v-model="focusedDate"
        ref="calendar"
        class="calendar"
        color="primary"
        :type="type"
        :events="events"
        :event-color="getEventColor"
        :weekdays="weekDayOrder"
        @change="$emit('change', $event)"
        @click:event="showEventDetail"
      />
    </div>
    <v-menu
      v-model="selectedOpen"
      absolute
      class="common-calender-dialog"
      :activator="selectedElement"
      :close-on-content-click="false"
      :position-x="priceDialogX"
      :position-y="priceDialogY"
    >
      <v-card v-if="selectedEvent" class="d-flex align-center">
        <slot
          name="dialog"
          :event="selectedEvent"
          :element="selectedElement"
          :loading="loading"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    events: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    type: {
      type: String,
      required: false,
      default: "month",
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      focusedDate: new Date(),
      selectedEvent: null,
      selectedElement: null,
      priceDialogX: 0,
      priceDialogY: 0,
      selectedOpen: false,
    };
  },

  mounted() {
    this.$refs.calendar.checkChange();
  },

  methods: {
    getEventColor(event) {
      return event?.color;
    },

    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    showToday() {
      this.focusedDate = new Date();
    },

    showEventDetail({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.priceDialogX = nativeEvent.pageX + 5;
        this.priceDialogY = nativeEvent.pageY - 70;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
  },

  computed: {
    weekDayOrder() {
      return [1, 2, 3, 4, 5, 6, 0];
    },
  },
};
</script>

<style scoped>
.common-calendar {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.common-calendar > .calendar-body {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.common-calendar > .calendar-body > .calendar {
  display: flex;
  flex-grow: 1;
}
</style>