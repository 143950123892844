export default {

    computed: {

        selectedDomain() {
            return this.$store.state.selectedDomain;
        },

    },

    methods: {

        sanitizeKeyPattern(keyPattern) {
            if (!keyPattern) return "";
            //removes trailing .* from given key pattern
            const regex = new RegExp("(\\.\\*)+$")
            return keyPattern.replace(regex, "");
        },

        updateTranslations(key, labels, existingTranslations) {
            if (!key || !labels) return;
            let translations = this.$cloneObject(existingTranslations) ?? [];
            if (Object.keys(labels).length === 0) {
                //if the labels object is empty, remove the translations for this i18nKey
                translations.forEach(translation => {
                    if (translation.valueset) this.$delete(translation.valueset, key);
                })
            }
            for (let locale in labels) {
                const label = labels[locale];
                const idx = existingTranslations.findIndex(trans => trans.locale === locale);
                if (idx < 0) {
                    let localization = { locale, valueset: {} };
                    this.$set(localization.valueset, key, label);
                    translations.push(localization);
                    continue;
                }
                this.$set(translations[idx].valueset, key, label);
            }

            return translations.filter(translation => translation.valueset && Object.keys(translation.valueset).length > 0);
        },

        async loadMergedSchema(domain, keySpace, keyPattern, localize) {
            return await this.$store.$coreApi.coreConfigurationApi.getMergedSchema(
                domain,
                keySpace,
                keyPattern,
                {
                    localize
                }
            );
        },

        /**
         * @param {*} keySpace key space of schema rule
         * @param {*} key key pattern of schema rule
         * @param {*} dataLocation data location of schema rle
         * @param {*} data data which should be stored
         * @returns An object with response status and optional errors array
         */
        async upsertData(keySpace, keyPattern, data, successMsg) {
            const res = await this.$store.$coreApi.coreConfigurationApi.upsertData(
                this.selectedDomain,
                keySpace,
                keyPattern,
                data,
                {
                    successMsg: successMsg ?? "Configuration " + keyPattern + " saved successfully"
                }
            );
            if (!res?.ok) {
                if (res?.status === 400) {
                    const violation = await res.json();
                    return {
                        status: 400,
                        violation
                    }
                } else {
                    return {
                        status: res?.status ?? 500,
                        violation: {
                            message: "Something went wrong while upserting data"
                        }
                    }
                }
            }
            return {
                status: res?.status
            };
        },

        async deleteData(keySpace, keyPattern, successMsg) {
            return await this.$store.dispatch("delete", {
                path: "/data/" + keySpace + "/" + keyPattern,
                successMsg: successMsg ?? "Configuration " + keyPattern + " deleted successfully"
            });
        },

        /**
         * @param {*} id ID of schema rule
         * @param {*} keySpace key space of schema rule
         * @param {*} key key pattern of schema rule
         * @param {*} dataLocation dataLocation of schema rule
         * @param {*} schema schema which should be stored
         * @returns An object with response status and optional errors array
         */
        async upsertSchemaRule(id, keySpace, key, dataLocation, schema, successMsg) {
            const body = {
                dataLocation,
                keySpace,
                keyPattern: key,
                schema
            };

            const res = await this.$store.$coreApi.coreConfigurationApi.upsertSchemaRule(
                this.selectedDomain,
                id,
                body,
                {
                    successMsg: successMsg ?? "Schema for Key " + key + " saved successfully"
                }
            )

            if (!res.ok) {
                this.$store.commit("SET_ERROR", res.status + " - " + res.statusText)
                if (res.status === 400) {
                    const violation = await res.json();
                    return {
                        status: res?.status ?? 500,
                        violation
                    };
                }
            }

            return { status: res?.status ?? 500 };
        },

        async deleteSchemaRule(id, successMsg) {
            return this.$store.dispatch("delete", {
                path: "/schemaRule/" + id,
                successMsg: successMsg ?? "Schema rule with id " + id + " deleted successfully"
            });
        },

        async upsertTranslations(translations, keySpace, keyPattern) {
            let path = "/localization";
            if (keySpace && keyPattern) path += "/" + keySpace + "/" + keyPattern;
            const res = this.$store.dispatch("patch", {
                path,
                body: translations,
                successMsg: null
            });
            return res;
        },

        async deleteTranslations(translationKeys, keySpace, keyPattern) {
            let query = ""
            const regex = new RegExp("^&");
            translationKeys.forEach(key => query += ("&i18nKeys=" + encodeURIComponent(key)));
            query = query.replace(regex, "");

            let successMsg = "Translations deleted";
            let path = "/localization";
            if (keySpace && keyPattern) {
                path += "/" + keySpace + "/" + keyPattern;
                successMsg = "Translations for " + keyPattern + " deleted";
            }

            const res = await this.$store.dispatch("delete", {
                path,
                query,
                successMsg
            });

            return res;
        },

        parseValidationErrorsToViolation(errors) {
            //if the data is invalid, parse the errors to violation
            //and return it
            const violations = errors.filter(error => !!error.message)
                .map((error) => {
                    if (error?.type === "required") {
                        //if the error type is required, get the correct
                        //property path from the message, since the path in the error
                        //is the one of the parent element
                        const parts = error.message.split(":");
                        const message = parts?.[1]?.trim();
                        const property = parts?.[0]?.trim();
                        return {
                            message,
                            property
                        }
                    }
                    else {
                        const message = error.message.replace(error?.path + ":", "").trim();
                        return {
                            message,
                            property: error.path,
                        }
                    }
                });

            const violation = {
                code: "data",
                message: "Validation failed",
                violations
            };

            return {
                valid: false,
                violation,
            };
        },

        getViolations(path, { returnObject = false } = {}) {
            if (this.violation?.violations) {
                return this.violation?.violations
                    .filter(({ property, message = "" }) => {
                        const includesPath = path === null || path === property;
                        if (path && path.includes("[") && path.includes("]")) {
                            //if path is sub property of array, check also if the
                            //message includes the path
                            return includesPath || message.includes(path + ":")
                        }
                        return includesPath;
                    })
                    .map((violation) => {
                        if (returnObject) return violation;
                        return violation?.message;
                    });
            }
            return [];
        },

        getSource(path) {
            const sources = this.sources ?? [];
            const source = sources.find(source => {
                return path === source.propertyPath
            });
            return this.$cloneObject(source);
        }


    }
}