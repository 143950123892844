<template>
  <div
    ref="masterDetail"
    :class="{
      'table-view': true,
    }"
  >
    <div
      :class="{
        'main-overview-container': true,
        'detail-opened': detailOpen,
      }"
      ref="container"
    >
      <div class="table-container" ref="table">
        <div class="toolbar-container" ref="toolbar">
          <slot name="toolbar" :loading="loading" />
        </div>
        <slot name="table" :loading="loading" />
      </div>
      <div
        v-if="detailOpen && !isMobile"
        class="slider"
        @mousedown="resizeTable"
        @dblclick="toggleDetail"
      >
        <v-btn icon x-small @click="toggleDetail">
          <v-icon v-if="isFullscreen">mdi-chevron-double-right</v-icon>
          <v-icon v-else>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-spacer />
        <v-icon v-if="!isFullscreen">mdi-dots-vertical</v-icon>
        <v-spacer />
      </div>
      <div v-if="detailOpen" class="detail-container" ref="detail">
        <slot name="detail" :loading="loading" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["getPageWidth"],

  props: {
    detailOpen: {
      type: Boolean,
      required: false,
      default: true,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isFullscreen: false,
      width: 0,
    };
  },

  mounted() {
    //initial emit of table width
    this.$nextTick(() => {
      const table = this.$refs.table;
      this.$emit("table:resize", table.offsetWidth);
    });
  },

  watch: {
    detailOpen(isOpen) {
      let width = "100%";
      if (isOpen) {
        width = this.isMobile ? 0 : "30%";
      }
      this.setTableWidth(width);
      this.isFullscreen = false;
    },

    isMobile(isMobile) {
      if (isMobile) {
        if (this.detailOpen) {
          this.setTableWidth(0);
          this.isFullscreen = true;
        }
      }
    },
  },

  methods: {
    resizeTable(evt) {
      const table = this.$refs.table;
      // get position of mouse
      let dragY = evt.clientX;
      // register a mouse move listener if mouse is down
      document.onmousemove = (evt) => {
        // e.clientY will be the position of the mouse as it has moved a bit now
        let tableWidth = table.offsetWidth + evt.clientX - dragY;
        this.setTableWidth(tableWidth + "px");
        this.isFullscreen = tableWidth === 0;
        // update variable - till this pos, mouse movement has been handled
        dragY = evt.clientX;
      };
      // remove mouse-move listener on mouse-up (drag is finished now)
      document.onmouseup = () =>
        (document.onmousemove = document.onmouseup = null);
    },

    setTableWidth(tableWidth) {
      const table = this.$refs.table;
      table.style.width = table.style.maxWidth = tableWidth;
      this.$nextTick(() => {
        this.$emit("table:resize", table.offsetWidth);
      });
    },

    toggleDetail() {
      const width = this.isFullscreen ? "30%" : "0px";
      this.isFullscreen = !this.isFullscreen;
      this.setTableWidth(width);
    },
  },

  computed: {
    isMobile() {
      return this.getPageWidth() <= 900;
    },
  },
};
</script>

<style scoped>
.table-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 0;
}

.table-view > .main-overview-container {
  margin-top: 4px;
}

.table-view > .main-overview-container > .table-container > .toolbar-container {
  width: 100%;
  margin-bottom: 12px;
}

.table-view > .main-overview-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
}

.table-view > .main-overview-container > .slider {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 24px;
  height: 100%;
  cursor: ew-resize;
  background-color: #eeeeee;
  z-index: 8;
  padding: 8px 0px;
}

.table-view > .main-overview-container > .table-container {
  display: flex;
  flex-direction: column;
}

.table-view > .main-overview-container:not(.detail-opened) > .table-container {
  flex-grow: 1;
  width: 100%;
}

.table-view > .main-overview-container.detail-opened > .table-container {
  resize: horizontal;
}

.table-view > .main-overview-container.detail-opened > .detail-container {
  display: flex;
  flex: 1;
  background: white;
  z-index: 8;
  overflow: hidden;
}

/* FULLSCREEN HANDLING */

.table-view.detail-fullscreen > .toolbar-container,
.table-view.detail-fullscreen
  > .main-overview-container.detail-opened
  > .table-container {
  display: none;
}
</style>

<style>
.table-view
  > .main-overview-container.detail-opened
  > .table-container
  > .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr {
  cursor: pointer;
}
</style>