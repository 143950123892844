<template>
  <DialogForm
    :loading="creatingRules"
    :title="title"
    data-test-id="ruleDialog"
    save-btn-label="Create"
    save-btn-test-id="createRuleBtn"
    close-btn-test-id="closeRuleDialogBtn"
    @close="$emit('close', false)"
    @save="createRule"
  >
    <template #form="{ loading }">
      <v-form ref="ruleForm">
        <v-select
          :items="ruleTypes"
          outlined
          dense
          hide-details="auto"
          v-model="type"
          data-test-id="catalogRuleType"
          label="Rule type"
          :disabled="loading"
          :rules="[catalogRules.required]"
        />
        <div v-if="type" class="d-flex flex-column pt-3">
          <DomainSelector
            v-if="type === 'SHARING'"
            v-model="targetDomain"
            label="Target catalog domain"
            hide-details="auto"
            outlined
            disable-domain-creation
            set-local
            class="mb-3"
            :disabled="loading"
            :forbidden-domains="[selectedDomain]"
            :rules="[catalogRules.required]"
          />

          <v-select
            v-if="targetDomain || type === 'FILTER'"
            v-model="targetCatalog"
            outlined
            return-object
            dense
            hide-details="auto"
            item-text="name"
            label="Target catalog"
            data-test-id="targetCatalogSelector"
            :items="catalogs"
            :disabled="loading"
            :rules="[catalogRules.required]"
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-title :data-test-id="'trg_catalog_' + item.id">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </v-form>
    </template>
  </DialogForm>
</template>

<script>
import DomainSelector from "components/domain/DomainSelector";
import mixin from "mixins/enum/CatalogEnum";
import DialogForm from "components/common/templates/DialogForm";
export default {
  mixins: [mixin],
  props: {
    catalog: {
      type: Object,
      required: true,
    },
  },

  components: {
    DomainSelector,
    DialogForm,
  },

  data() {
    return {
      catalogs: [],
      rule: {
        srcCatalog: this.catalog.id,
      },
      type: null,
      targetCatalog: null,
      targetCatalogIndex: null,
      targetDomain: undefined,
      creatingRules: false,
    };
  },

  async mounted() {
    await this.loadCatalogs();
  },

  watch: {
    async targetCatalog(catalog) {
      this.rule.catalog = catalog.id;
      if (this.type === "FILTER") {
        this.rule.filter = JSON.stringify({
          bool: {
            filter: [],
          },
        });
      }
    },

    "targetDomain.id": async function () {
      await this.loadCatalogs(this.targetDomain);
    },
  },

  methods: {
    async loadCatalogs(targetDomain) {
      const domain = targetDomain?.id ?? this.selectedDomain;
      const catalogs = await this.$store.$coreApi.coreCatalogApi.getCatalogs(
        domain
      );
      //only catalogs of type filter can be target catalogs
      this.catalogs = catalogs.filter(
        ({ id, type }) => type === "FILTER" && id !== this.catalog.id
      );
    },

    async createRule() {
      if (!this.$refs.ruleForm.validate()) return;
      this.creatingRules = true;
      try {
        const id = this.$uuid.v4();
        this.$set(this.rule, "id", id);
        const method = this.type === "SHARING" ? "SharingRule" : "FilterRule";
        const duplicate = await this.$store.$coreApi.coreCatalogApi[
          "get" + method
        ](this.selectedDomain, this.rule.id, true);
        if (duplicate?.ok !== false) {
          this.$store.dispatch(
            "setError",
            "Rule of type " +
              this.type +
              " with id " +
              id +
              " does already exist"
          );
          return;
        }
        const successMsg =
          (this.type === "SHARING" ? "Sharing" : "Filter") +
          " rule " +
          this.rule.id +
          " created";
        const res = await this.$store.$coreApi.coreCatalogApi[
          "update" + method
        ](this.selectedDomain, this.rule, successMsg);
        if (!res?.ok) return;
        this.$emit("created", this.rule);
      } finally {
        this.creatingRules = false;
      }
    },
  },

  computed: {
    ruleTypes() {
      return [
        { text: "FILTER", value: "FILTER" },
        { text: "SHARING", value: "SHARING" },
      ];
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    title() {
      return "New" + (this.type ?? "") + " rule";
    },
  },
};
</script>