<template>
  <div ref="detailView" class="common-detail-view">
    <v-toolbar fixed class="detail-view-toolbar">
      <v-btn icon data-test-id="closeDetailBtn" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="text-truncate" data-test-id="detailTitle">
        {{ title }}
      </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <slot name="subtitle" :loading="loading" />
      <v-spacer></v-spacer>
      <div v-if="loading" class="px-4">
        <v-progress-circular
          indeterminate
          :size="24"
          :width="2"
          color="grey darken-3"
        />
      </div>
      <slot name="actions" :loading="loading" />
      <template v-if="hasTabs" v-slot:extension>
        <slot name="tabs" :loading="loading">
          <v-tabs v-model="selectedTab" ref="tabs" grow>
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              :data-test-id="'detail_tab_' + tab.value"
              :disabled="loading"
            >
              {{ tab.text }}
            </v-tab>
          </v-tabs>
        </slot>
      </template>
    </v-toolbar>
    <div
      :class="{
        'detail-view-content': true,
        'has-tabs': hasTabs,
      }"
      data-test-id="detailViewContent"
    >
      <slot v-if="hasTabs" name="tabs.content" :tab="selectedTab">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item
            v-for="tab in tabs"
            :key="tab.value"
            :data-test-id="'detail_tab_' + tab.value + '_body'"
            :eager="eagerTabs"
            class="detail-view-body"
          >
            <slot :name="'tab.' + tab.value" :tab="tab" :loading="loading" />
          </v-tab-item>
        </v-tabs-items>
      </slot>
      <div v-else class="detail-view-body">
        <slot name="content" :loading="loading" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },

    title: {
      type: String,
      required: false,
      default: "Detail View",
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    tabs: {
      type: Array,
      required: false,
    },

    /* 
      If true will render tabs even if
      they are not visible.

      Useful for form validation on multiple tab views 
    */
    eagerTabs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selectedTab: this.value,
    };
  },

  mounted() {
    this.$nextTick(() => {
      //Add resize oberserver
      const detailView = this.$refs.detailView;
      new ResizeObserver(this.onResize).observe(detailView);
    });
  },

  watch: {
    selectedTab(tab) {
      this.$emit("input", tab);
    },

    value(value) {
      this.selectedTab = value;
    },
  },

  methods: {
    onResize() {
      this.$refs?.tabs?.onResize?.();
      this.$emit("resize");
    },
  },

  computed: {
    hasTabs() {
      return this.tabs?.length > 0 || this.hasTabsSlot;
    },

    hasTabsSlot() {
      return !!this.$slots.tabs || !!this.$slots["tabs.content"];
    },
  },
};
</script>

<style scoped>
.common-detail-view {
  display: flex;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  flex-grow: 1;
  align-items: flex-start;
  align-content: flex-start;
  overflow-y: hidden;
  flex-direction: column;
  border: 1px solid var(--v-psblue-base);
}

.common-detail-view > .detail-view-toolbar {
  width: 100%;
  flex: 0 1 auto;
}

.common-detail-view > .detail-view-toolbar::v-deep .v-btn + .v-btn {
  margin-left: 0.5rem;
}

.common-detail-view > .detail-view-content {
  height: calc(100% - 64px);
  width: 100%;
  margin: 12px 0 0 0;
  overflow-y: scroll;
  padding-bottom: 32px;
}

.common-detail-view > .detail-view-content.has-tabs {
  height: calc(100% - 122px);
}

.detail-view-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
  width: 100%;
}
</style>