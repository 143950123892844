<template>
  <DialogForm
    :loading="creatingCatalog"
    :title="title"
    data-test-id="ruleDialog"
    save-btn-label="Create"
    close-btn-label="Cancel"
    save-btn-test-id="createCatalogBtn"
    close-btn-test-id="cancelCreateCatalogBtn"
    @close="$emit('close', false)"
    @save="createCatalog"
  >
    <template #form="{ loading }">
      <v-form ref="catalogForm">
        <v-container class="py-0">
          <v-select
            v-model="catalog.type"
            dense
            outlined
            hide-details="auto"
            label="Catalog type"
            data-test-id="catalogTypeSelector"
            :items="types"
            :disabled="types.length === 1 || loading"
            :rules="[catalogRules.required]"
          />
        </v-container>
        <v-expand-transition>
          <v-container v-if="catalog.type" class="create-catalog-attributes">
            <v-text-field
              v-model="catalog.name"
              outlined
              dense
              hide-details="auto"
              label="Name"
              data-test-id="newCatalogName"
              :disabled="loading"
              :rules="[catalogRules.required]"
            />
            <v-checkbox
              v-model="catalog.published"
              dense
              outlined
              label="Published"
              data-test-id="newCatalogPublished"
              :disabled="loading"
            />
          </v-container>
        </v-expand-transition>
      </v-form>
    </template>
  </DialogForm>
</template>

<script>
import mixin from "../../mixins/enum/CatalogEnum";
import DialogForm from "../common/templates/DialogForm";
export default {
  mixins: [mixin],

  components: {
    DialogForm,
  },

  props: {
    allowedTypes: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      catalog: {},
      creatingCatalog: false,
    };
  },

  mounted() {
    if (this.types.length === 1) {
      this.$set(this.catalog, "type", this.types[0]?.value);
    }
  },

  methods: {
    async createCatalog() {
      this.creatingCatalog = true;
      try {
        if (!this.$refs.catalogForm.validate()) return;
        this.$set(this.catalog, "id", this.$uuid.v4());
        //Check if there is already a catalog with the same ID
        const duplicate = await this.$store.$coreApi.coreCatalogApi.getCatalog(
          this.selectedDomain,
          this.catalog.id,
          true
        );
        //check for id, because if an 404 error is returned
        //just checking if the duplicate != undefined would not be sufficient
        if (duplicate?.id === this.catalog.id) {
          this.$store.dispatch(
            "setError",
            "Catalog with id " + this.catalog.id + " does already exist"
          );
          return;
        }
        const res = await this.$store.$coreApi.coreCatalogApi.updateCatalog(
          this.selectedDomain,
          this.catalog,
          "Catalog " + this.catalog.name + " created"
        );
        if (!res?.ok) return;
        this.$emit("create", this.catalog);
      } finally {
        this.creatingCatalog = false;
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    types() {
      const types = [
        { text: "FILTER", value: "FILTER" },
        { text: "IMPORT", value: "IMPORT" },
        { text: "MANUAL", value: "MANUAL" },
      ];
      if (this.allowedTypes && this.allowedTypes.length > 0) {
        return types.filter(({ value }) => this.allowedTypes.includes(value));
      }
      return types;
    },

    title() {
      return "New " + (this.catalog.type ?? "") + " Catalog";
    },
  },
};
</script>

<style scoped>
.create-catalog-container .v-text-field {
  margin-bottom: 10px;
}

.catalog-products-container .filter-catalog-rules {
  padding: 0;
}

.catalog-products-filter {
  overflow-y: scroll;
  max-height: 80%;
  border: 1px solid lightgrey;
  padding: 0;
  margin-top: 5px;
}

.create-catalog-container .v-btn {
  margin-left: 5px;
}
</style>