<template>
  <v-container class="pagination-container">
    <button
      v-if="!textButtons || (page && page > 1)"
      type="button"
      :class="{
        'v-pagination__navigation': true,
        'pagination-previous-btn': true,
        'text-btn': textButtons,
      }"
      data-test-id="prevPageBtn"
      :disabled="previousBtnDisabled"
      @click="page--"
    >
      <v-icon :left="textButtons" color="blue" :disabled="previousBtnDisabled"
        >mdi-menu-left</v-icon
      >
      <div
        v-if="textButtons"
        :class="{
          'blue--text': true,
          'text--disabled': previousBtnDisabled,
        }"
      >
        PREVIOUS
      </div>
    </button>
    <div class="pagination-input">
      <input
        v-if="!hidePageInput"
        :value="page"
        :disabled="(hasTotalPages && totalPages < 2) || disabled"
        :max="totalPages"
        class="v-pagination__item pagination-number-input"
        type="number"
        min="1"
        step="1"
        data-test-id="currentPageInput"
        @change="handlePageChange"
      />
      <div
        v-if="hasTotalPages"
        class="pagination-total"
        data-test-id="totalPages"
      >
        / {{ totalPages }}
      </div>
    </div>
    <button
      v-if="!textButtons || !totalPages || page < totalPages"
      type="button"
      :class="{
        'v-pagination__navigation': true,
        'pagination-after-btn': true,
        'text-btn': textButtons,
      }"
      data-test-id="nextPageBtn"
      :disabled="nextBtnDisabled"
      @click="page++"
      @keydown.enter.prevent
    >
      <div
        v-if="textButtons"
        :class="{
          'blue--text': true,
          'text--disabled': nextBtnDisabled,
        }"
      >
        NEXT
      </div>
      <v-icon :right="textButtons" color="blue" :disabled="nextBtnDisabled"
        >mdi-menu-right</v-icon
      >
    </button>
  </v-container>
</template>

<script>
import { VPagination } from "vuetify/lib";

export default {
  extends: VPagination,

  props: {
    value: {
      type: Number,
      required: true,
    },

    totalPages: {
      type: Number,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    hidePageInput: {
      type: Boolean,
      required: false,
      default: false,
    },

    textButtons: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableNextBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      page: this.value,
    };
  },

  watch: {
    page: function (value) {
      if (!value || value < 1) value = 1;
      if (this.hasTotalPages && value > this.totalPages)
        value = this.totalPages;
      this.$emit("input", value);
    },

    value: function (value) {
      this.page = value;
    },
  },

  methods: {
    handlePageChange(event) {
      //change the page according to the pagination components input
      this.page = parseInt(event.target.value);
    },
  },

  computed: {
    hasTotalPages() {
      return this.totalPages !== undefined;
    },

    nextBtnDisabled() {
      return (
        this.disableNextBtn ||
        (this.hasTotalPages && this.page >= this.totalPages) ||
        !this.page ||
        this.disabled
      );
    },

    previousBtnDisabled() {
      return this.page <= 1 || !this.page || this.disabled;
    },
  },
};
</script>

<style scoped>
.pagination-container {
  width: fit-content;
  display: flex;
  margin: 0;
}

.pagination-container > .pagination-input {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 4px;
}

.pagination-total {
  display: flex;
  flex-grow: 1;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination-container > .v-pagination__navigation {
  display: flex;
  flex-grow: 1;
  height: 100%;
  min-height: 32px;
  margin-left: 0;
  margin-right: 0;
}

.v-pagination__navigation.text-btn {
  width: 128px;
  display: flex;
  padding: 12px;
  min-height: 36px;
}

.pagination-container > .pagination-input > input.pagination-number-input {
  text-align: center;
  max-height: 34px;
  width: 6ch;
  margin-right: 4px;
  outline: none;
  display: flex;
  flex-grow: 1;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>