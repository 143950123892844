<template>
  <v-container fluid class="schema-reader" data-test-id="schemaReaderContainer">
    <v-row dense data-test-id="schemaReaderShownLocales">
      <v-col cols="3">
        <v-subheader>Displayed Locales</v-subheader>
      </v-col>
      <v-col>
        <v-chip-group
          v-model="shownLocales"
          color="primary"
          column
          multiple
          active-class="primary--text"
        >
          <v-chip
            v-for="locale in supportedLocales"
            filter
            :key="locale"
            :value="locale"
            :data-test-id="'shownLocale_' + locale"
          >
            {{ locale }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row v-for="(message, index) in warningMessages" :key="index" dense>
      <v-col class="warning--text d-flex align-start">
        <v-icon left color="warning"> mdi-alert </v-icon>
        <div v-html="message" />
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <SchemaAttributesReader v-if="schema" :schema="schema" />
    </v-row>
  </v-container>
</template>

<script>
import SchemaAttributesReader from "./SchemaAttributesReader";
export default {
  components: {
    SchemaAttributesReader,
  },

  props: {
    schema: {
      type: Object,
      required: false,
    },
  },

  provide() {
    return {
      getShownLocales: this.getShownLocales,
      addWarningMessage: this.addWarningMessage,
    };
  },

  data() {
    return {
      shownLocales: [],
      warningMessages: [],
    };
  },

  watch: {
    schema: {
      handler: function () {
        this.warningMessages = [];
      },
      deep: true,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.shownLocales = [this.fallbackLocale];
    });
  },

  methods: {
    getShownLocales() {
      return this.shownLocales;
    },

    addWarningMessage(property, message) {
      const displayedMessage = this.$sanitize(
        "<strong>" + property + "</strong>: " + message,
        {
          FORBID_TAGS: ["style", "input", "textarea", "button", "form"],
          FORBID_ATTR: ["style", "class"],
        }
      );

      this.warningMessages.push(displayedMessage);
    },
  },

  computed: {
    supportedLocales() {
      return this.$store.state.localization.supportedLocales;
    },

    fallbackLocale() {
      return this.$store.state.localization.fallbackLocale;
    },

    hasWarningMessages() {
      return this.warningMessages.length === 0;
    },
  },
};
</script>

<style scoped>
</style>