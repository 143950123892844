<template>
  <v-container fluid class="manual-catalog-products">
    <v-toolbar flat class="manual-catalog-products-toolbar">
      <v-toolbar-title>Products</v-toolbar-title>
      <v-divider vertical inset class="mx-4"></v-divider>
      <v-spacer></v-spacer>

      <v-dialog max-width="800px" v-model="showDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs"> New Product </v-btn>
        </template>
        <v-toolbar>
          <v-toolbar-title>New Product</v-toolbar-title>
          <v-divider inset vertical class="mx-4"></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="addProduct"> Create </v-btn>
          <v-btn @click="showDialog = false"> Cancel </v-btn>
        </v-toolbar>
        <ProductDetail v-model="newProduct" :editable="true" />
      </v-dialog>
    </v-toolbar>
    <v-data-table
      dense
      :items="products"
      :headers="headers"
      :single-select="true"
      class="manual-catalog-products-table"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="removeProduct(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ProductDetail from "../products/ProductDetail";

export default {
  props: ["value"],

  components: {
    ProductDetail,
  },

  data() {
    return {
      products: this.value || [],
      newProduct: {},
      showDialog: false,
    };
  },

  methods: {
    addProduct() {
      this.products.push(this.newProduct);
      this.newProduct = new Object();
      this.showDialog = false;
    },

    removeProduct(product) {
      this.products.splice(
        this.products.findIndex((item) => item.sku == product.sku),
        1
      );
    },
  },

  computed: {
    headers() {
      return [
        { text: "SKU", value: "sku" },
        { text: "Service provider", value: "sps" },
        { text: "External ID", value: "externalId" },
        { text: "", value: "actions" },
      ];
    },
  },
};
</script>

<style scoped>
.manual-catalog-products {
  padding: 0;
}
</style>