export default {
    inject: ["getPageHeight", "getPageWidth"],
    computed: {
        tableHeight() {
            return this.getPageHeight() - 166;
        },

        detailHeight() {
            return this.getPageHeight() - 4;
        },
    }
} 