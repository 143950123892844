export default {

    methods: {

        async getCatalogRules() {
            let rules = [];
            const domain = this.$store.state.selectedDomain;
            const filterRules = await this.$store.$coreApi.coreCatalogApi.getFilterRules(domain);
            if (filterRules) {
                filterRules.forEach((rule) => rules.push(rule));
            }
            const sharingRules = await this.$store.$coreApi.coreCatalogApi.getSharingRules(domain);
            if (sharingRules) {
                sharingRules.forEach((rule) => rules.push(rule));
            }
            return rules;
        }

    },
}