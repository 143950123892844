<template>
  <v-container fluid class="catalog-rules-container">
    <v-toolbar flat class="catalog-rules-toolbar">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog v-model="showRuleDialog" persistent max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            right
            v-on="on"
            v-bind="attrs"
            color="primary"
            data-test-id="newRuleBtn"
            :disabled="isDisabled"
          >
            New rule
          </v-btn>
        </template>
        <CatalogRule
          v-if="catalog && showRuleDialog"
          :catalog="catalog"
          data-test-id="createRuleDialog"
          @created="loadRules"
          @close="showRuleDialog = false"
        />
      </v-dialog>
    </v-toolbar>

    <v-card v-if="!rules || rules.length == 0" class="rule-card elevation-6">
      <v-card-text class="d-flex justify-center"> No rules yet </v-card-text>
    </v-card>

    <!-- RULE LIST -->
    <v-card
      v-for="(rule, index) in rules"
      :key="index + rule.id"
      class="rule-card elevation-6"
    >
      <CatalogRuleDetail
        :catalog-rule="rule"
        :data-test-id="'rule_' + rule.id"
        :disabled="isDisabled"
        @deleted="removeRule(index)"
      />
    </v-card>
  </v-container>
</template>

<script>
import CatalogRule from "./CatalogRule";
import CatalogRuleDetail from "./CatalogRuleDetail";
import mixin from "../../../mixins/catalog-rules-mixin";

export default {
  mixins: [mixin],

  components: {
    CatalogRule,
    CatalogRuleDetail,
  },

  props: {
    catalog: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: "Rules",
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mounted() {
    this.loadRules();
  },

  data() {
    return {
      rules: [],
      showRuleDialog: false,
      runningAction: false,
    };
  },

  watch: {
    rules(rules) {
      this.$emit("input", rules);
    },

    "catalog.id": function () {
      this.loadRules();
    },
  },

  methods: {
    async loadRules() {
      try {
        this.runningAction = true;
        this.showRuleDialog = false;
        const rules = await this.getCatalogRules();
        this.rules = rules.filter(
          ({ srcCatalog }) => srcCatalog === this.catalog.id
        );
      } finally {
        this.runningAction = false;
      }
    },

    removeRule(index) {
      this.rules.splice(index, 1);
      this.$emit("reload");
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    isDisabled() {
      return this.runningAction || this.disabled;
    },
  },
};
</script>

<style scoped>
.catalog-rules-container > .rule-card {
  padding: 12px;
}

.catalog-rules-container > .catalog-rules-toolbar,
.catalog-rules-container > .rule-card {
  margin-bottom: 12px;
}
</style>