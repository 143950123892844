var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status-chip"},[(!_vm.showProgress)?_c('v-chip',{class:{
            'justify-center': true,
            'clickable': _vm.clickable && !_vm.loading
        },attrs:{"color":_vm.color,"dark":_vm.color !== _vm.stateColor.PENDING,"label":_vm.label,"disabled":_vm.loading || _vm.disabled,"small":_vm.small},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(!_vm.loading)?_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.content)+" "),(_vm.appendIcon)?_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" "+_vm._s(_vm.appendIcon)+" ")]):_vm._e()],1):_c('div',{staticClass:"px-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":_vm.small ? 16 : 24,"width":_vm.small ? 2 : 4,"color":"grey darken-3"}})],1)]):_c('div',{directives:[{name:"ripple",rawName:"v-ripple",value:(_vm.clickable),expression:"clickable"}],class:{
            'progress-bar': true,
            'clickable': _vm.clickable && !_vm.loading
        },style:({
            width: _vm.progressBarWidth + 'px',
        }),attrs:{"data-test-id":"progressBar"},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('v-progress-linear',{staticClass:"v-chip v-size--default",class:{
                small: _vm.small
            },attrs:{"value":_vm.progressValue,"color":!_vm.loading ? _vm.color : 'grey',"height":_vm.small ? 24 : 32,"rounded":""}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{ref:"progressText",class:{
                        'white--text':  _vm.color !== _vm.stateColor.PENDING,
                        'black--text':  _vm.color === _vm.stateColor.PENDING  
                    }},[_vm._v(" "+_vm._s(_vm.progressText)+" ")]),(_vm.appendIcon)?_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" "+_vm._s(_vm.appendIcon)+" ")]):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }