<template>
  <v-card outlined class="generic-attributes-card">
    <v-card-title v-if="title">
      <v-icon left v-if="icon">{{ icon }}</v-icon>
      {{ title }}
    </v-card-title>
    <v-container>
      <Reader
        v-if="hasAttributes"
        :title="title"
        :data="attributes"
        :schema="attributesSchema"
        hide-root-node
        disable-json
        initial-closed
      />
      <div v-else class="d-flex justify-center">
        <v-subheader>No entries found</v-subheader>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Reader from "../../configuration/data/Reader";
export default {
  components: {
    Reader,
  },
  props: {
    title: {
      type: String,
      required: false,
    },

    icon: {
      type: String,
      required: false,
    },

    attributes: {
      type: Object,
      required: false,
    },
  },

  computed: {
    hasAttributes() {
      return (
        this.$isPlainObject(this.attributes) &&
        Object.keys(this.attributes).length > 0
      );
    },

    attributesSchema() {
      return {
        type: "object",
        additionalProperties: true,
      };
    },
  },
};
</script>

<style scoped>
.generic-attributes-card {
  display: flex;
  flex: 1 1 100%;
  flex-flow: column wrap;
  height: 100%;
  text-align: left;
}

.generic-attributes-card .v-list-item__title {
  overflow: visible;
  text-overflow: unset;
  white-space: unset;
}
</style>