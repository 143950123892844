<template>
  <DetailView
    :title="value.name"
    :tabs="tabs"
    :loading="runningAction"
    @close="$emit('close', false)"
  >
    <template #actions="{ loading }">
      <v-btn
        outlined
        data-test-id="scheduleIndexBtn"
        :disabled="loading"
        @click="scheduleIndex"
      >
        <v-icon left>mdi-update</v-icon>
        Schedule reindex
      </v-btn>
      <v-btn
        color="green"
        class="ml-2"
        data-test-id="saveCatalogBtn"
        :disabled="loading"
        :outlined="!hasChanged"
        @click="saveCatalog"
      >
        Save
      </v-btn>
      <v-btn
        outlined
        color="red"
        class="ml-2"
        data-test-id="deleteCatalogBtn"
        :disabled="loading"
        @click="deleteCatalog"
      >
        <ViolationAlert
          v-if="hasDeleteViolation"
          :violation="deleteViolation"
          color="error"
          alignment="left"
        />
        Delete
      </v-btn>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.properties="{ loading }">
      <v-container
        fluid
        class="catalog-detail-properties"
        data-test-id="catalogProperties"
      >
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title data-test-id="catalogId">{{
                catalog.id
              }}</v-list-item-title>
              <v-list-item-subtitle>ID</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title data-test-id="catalogType">{{
                catalog.type
              }}</v-list-item-title>
              <v-list-item-subtitle>Type</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title data-test-id="catalogIndexVersion">{{
                catalog.indexVersion
              }}</v-list-item-title>
              <v-list-item-subtitle>Index Version</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title data-test-id="catalogVersion">{{
                catalog.catalogVersion
              }}</v-list-item-title>
              <v-list-item-subtitle>Catalog Version</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title data-test-id="catalogCleanState">
                <StatusChip label :status="catalogState" />
              </v-list-item-title>
              <v-list-item-subtitle>State</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title data-test-id="catalogMarkDirtyAt">
                {{
                  catalog.markDirtyAt
                    ? $getLocalizedDate(catalog.markDirtyAt)
                    : "-"
                }}
              </v-list-item-title>
              <v-list-item-subtitle>Mark DIRTY at</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-form @submit.prevent ref="catalogForm">
          <v-text-field
            v-model="catalog.name"
            outlined
            dense
            label="Name"
            data-test-id="catalogName"
            hide-details="auto"
            :rules="[(v) => !!v || 'Name is required']"
            :disabled="loading"
          />
          <v-checkbox
            v-model="catalog.published"
            dense
            outlined
            label="Published"
            data-test-id="catalogPublished"
            :disabled="loading"
          />
        </v-form>
      </v-container>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.products="{ loading }">
      <v-container
        fluid
        class="catalog-products-container"
        data-test-id="catalogProducts"
      >
        <v-alert
          type="error"
          icon="mdi-alert"
          v-if="catalog.cleanState !== 'CLEAN'"
        >
          Catalog state is not CLEAN! Shown products may not be complete.
        </v-alert>
        <ProductOverview
          v-if="catalog.type !== 'MANUAL'"
          open-details-in-tab
          :catalog="catalog"
          :key="catalog.id"
        />
        <ManualCatalogProducts v-else v-model="products" />
      </v-container>
    </template>
    <!-- eslint-disable-next-line -->
    <template #tab.rules="{ loading }">
      <CatalogRuleOverview
        v-model="catalogRules"
        data-test-id="catalogRuleList"
        :catalog="catalog"
        :disabled="runningAction"
        @reload="loadCatalog"
      />
    </template>
  </DetailView>
</template>

<script>
import CatalogRuleOverview from "./rule/CatalogRuleOverview";
import ManualCatalogProducts from "./ManualCatalogProducts";
import StatusChip from "../common/display-helpers/StatusChip";
import DetailView from "../common/templates/DetailView";
import ProductOverview from "../products/ProductOverview";
import ViolationAlert from "../common/display-helpers/ViolationAlert";

export default {
  components: {
    DetailView,
    CatalogRuleOverview,
    ManualCatalogProducts,
    StatusChip,
    ProductOverview,
    ViolationAlert,
  },

  inject: ["getPageHeight"],

  provide() {
    return {
      //provide page height, so that the product overview
      //calculates the correct table height
      getPageHeight: this.getProductOverviewHeight,
    };
  },

  props: {
    value: {
      type: Object,
      required: true,
    },

    rules: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      catalog: this.$cloneObject(this.value),
      savedCatalog: this.$cloneObject(this.value),
      catalogRules: this.rules,
      products: [],
      selectedTab: "Products",
      runningAction: false,
      hasChanged: false,
      deleteViolation: null,
    };
  },

  mounted() {
    this.loadCatalog();
  },

  watch: {
    "value.id": function () {
      this.loadCatalog();
    },

    catalog: {
      handler: function (catalog) {
        this.hasChanged = !this.$isEqual(catalog, this.savedCatalog);
      },
      deep: true,
    },

    savedCatalog: {
      handler: function (savedCatalog) {
        this.hasChanged = !this.$isEqual(this.catalog, savedCatalog);
      },
      deep: true,
    },

    hasChanged(hasChanged) {
      this.$set(this.$route.params, "unsavedChanges", hasChanged);
    },
  },

  methods: {
    async loadCatalog() {
      try {
        this.runningAction = true;
        this.deleteViolation = null;
        this.catalog = await this.$store.$coreApi.coreCatalogApi.getCatalog(
          this.selectedDomain,
          this.value.id
        );
        this.savedCatalog = this.$cloneObject(this.catalog);
      } finally {
        this.runningAction = false;
      }
    },

    async deleteCatalog() {
      try {
        this.runningAction = true;
        const isValid = await this.validateDelete();
        if (!isValid) return;
        const deleteConfirmed = await this.$confirm(
          "Delete catalog?",
          `Are you sure you want to delete catalog ` + this.catalog.id + `?`
        );
        if (deleteConfirmed) {
          const res = await this.$store.$coreApi.coreCatalogApi.deleteCatalog(
            this.selectedDomain,
            this.catalog
          );

          if (res?.ok) {
            this.hasChanged = false;
            this.$nextTick(() => this.$emit("delete"));
          }
        }
      } finally {
        this.runningAction = false;
      }
    },

    async saveCatalog() {
      if (!this.$refs.catalogForm.validate()) return;
      this.runningAction = true;
      try {
        const res = await this.$store.$coreApi.coreCatalogApi.updateCatalog(
          this.selectedDomain,
          this.catalog
        );

        this.hasChanged = false;
        if (res?.ok) this.$emit("save", this.catalog);
      } finally {
        this.runningAction = false;
      }
    },

    getProductOverviewHeight() {
      return (
        this.getPageHeight() -
        64 -
        136 -
        (this.catalogState !== "CLEAN" ? 72 : 0)
      );
    },

    async scheduleIndex() {
      try {
        this.runningAction = true;
        await this.$store.$coreApi.coreCatalogApi.scheduleIndex(
          this.selectedDomain,
          "asap",
          this.catalog.id,
          {
            successMsg: "Scheduled indexing of " + this.value.name,
          }
        );

        this.$emit("save");
      } finally {
        this.runningAction = false;
      }
    },

    async validateDelete() {
      if (this.catalog.published) {
        this.deleteViolation = {
          message: "Catalog is published!",
        };
        return false;
      }

      if (
        this.savedCatalog.published === true &&
        this.catalog.published === false
      ) {
        //User changed publish state before delete, so save the catalog so that
        //the published state is correctly set on the backend and the validation does not fail
        const saveRes = await this.$store.$coreApi.coreCatalogApi.updateCatalog(
          this.selectedDomain,
          this.catalog,
          null
        );

        if (!saveRes?.ok) {
          this.deleteViolation = {
            message: "Catalog is published!",
          };
          return false;
        }

        this.savedCatalog = this.$cloneObject(this.catalog);
      }

      const incomingDep = this.catalog.incomingDependenciesCount;
      const outgoingDep = this.catalog.outgoingDependenciesCount;

      if (outgoingDep > 0 || incomingDep > 0) {
        this.deleteViolation = {
          message:
            `
            Catalog is still used in catalog rules!
              <ul>
                <li>Is <strong>target</strong> catalog of <strong>` +
            incomingDep +
            `</strong> rules
            </li>
                <li>Is <strong>source</strong> catalog of <strong>` +
            outgoingDep +
            `</strong> rules
            </li>
              <ul>
          `,
        };
        return false;
      }

      return true;
    },
  },

  computed: {
    tabs() {
      return [
        { text: "Properties", value: "properties" },
        { text: "Products", value: "products" },
        { text: "Rules", value: "rules" },
      ];
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    catalogState() {
      return this.catalog?.cleanState ?? "ERROR: NO STATE!";
    },

    hasDeleteViolation() {
      return !!this.deleteViolation;
    },
  },
};
</script>

<style scoped>
.catalog-detail-properties {
  width: 100%;
  height: 100%;
  align-self: flex-start;
  top: 0px;
  align-items: flex-start;
  overflow-y: scroll;
  text-align: left;
}

.catalog-detail-properties > .v-list {
  display: flex;
  flex-flow: row wrap;
}

.catalog-detail-properties > .v-list::v-deep .v-list-item {
  flex: 1 1 auto;
  padding: 0;
}

.catalog-detail-properties > .v-list::v-deep .v-list-item .v-list-item__title,
.catalog-detail-properties
  > .v-list::v-deep
  .v-list-item
  .v-list-item__subtitle {
  display: flex;
  justify-content: center;
}

.catalog-products-container {
  width: 100%;
  height: 100%;
  padding: 0;
}

.catalog-products-container > .v-alert::v-deep .v-alert__wrapper {
  justify-content: center;
}

.catalog-products-container
  > .v-alert::v-deep
  .v-alert__wrapper
  > .v-alert__content {
  flex: 0 1 auto;
}
</style>