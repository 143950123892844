export default {

    methods: {
        addDates(fromDate, untilDate, dateArray, validityPeriods) {
            //Adds all dates between fromDate and untilDate to the given dates array
            let currentDate = fromDate;

            while (this.$getDayDiff(currentDate, untilDate) >= 0) {
                let valid = true;
                if (validityPeriods) {
                    //check if the date is within any validity period
                    //if not, the date is invalid
                    valid = validityPeriods.some((period) => {
                        let fromDate = new Date(period.fromDate);
                        let untilDate = new Date(period.untilDate);
                        return (
                            (this.$getDayDiff(currentDate, fromDate) === 0 ||
                                currentDate.getTime() > fromDate.getTime()) &&
                            (this.$getDayDiff(untilDate, currentDate) === 0 ||
                                currentDate.getTime() <= untilDate.getTime())
                        );
                    });
                }

                dateArray.push({
                    date: currentDate,
                    valid,
                });

                currentDate = this.addDays(currentDate, 1);
            }
        },

        addDays(date, days) {
            //add days to a given date by copying the dates value,
            //adding the given amount of days and returning the new date
            let newDate = new Date(date.valueOf());
            newDate.setDate(newDate.getDate() + days);
            return newDate;
        },
    }

}