export default{

    methods: {

        searchRequestBody({limit, offset, sort, query, _source}){
            let searchBody ={
                version: true,
                track_total_hits: true,
                sort: [],
                _source: true,
                query: {
                    bool: {
                        filter: [],
                        must: [],
                        should: [],
                        must_not: []
                    }
                }
            }

            if(limit) searchBody.size = limit;
            if(offset) searchBody.from = offset;
            if(sort) searchBody.sort = sort;
            if(query) searchBody.query = query;
            if(_source) searchBody._source = _source

            return searchBody;
        },

        skuFilterQuery(sku){
            return {
                bool: {
                    filter: [
                        {
                            wildcard: {
                                ["sku.keyword"]: {
                                    value: sku
                                }
                            }
                        } 
                    ]
                }
            }
        }

    }

}