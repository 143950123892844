var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"price-list-viewer"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Price Lists")])],1),_c('v-data-table',{attrs:{"items":_vm.priceLists,"headers":_vm.headers,"items-per-page":-1,"sort-desc":false,"sort-by":"fromDate","expanded":_vm.expanded,"hide-default-footer":"","show-expand":"","data-test-id":"priceListTable"},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(isExpanded)?_c('v-btn',{attrs:{"icon":"","data-test-id":'price_list_hide_btn_' + item.id},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"icon":"","data-test-id":'price_list_expand_btn_' + item.id},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.fromDate))+" ")]}},{key:"item.untilDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.untilDate))+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length+1}},[_c('v-container',{staticClass:"price-list-detail",attrs:{"data-test-id":'price_list_detail_' + item.id}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":"","outlined":"","data-test-id":'price_list_name_' + item.id}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-translate")]),_vm._v(" Name ")],1),_c('v-list',_vm._l((_vm.getNames(item)),function(name,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(name.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(name.language))])],1)],1)}),1)],1)],1),(item.attributes)?_c('v-col',[_c('GenericAttributesCard',{key:item.id,attrs:{"title":"Attributes","icon":"mdi-format-list-bulleted-square","attributes":item.attributes,"data-test-id":'price_list_attributes_' + item.id}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":"","outlined":"","data-test-id":'price_list_prices_' + item.id}},[_c('v-card-title',[_vm._v("Prices")]),_c('v-data-table',{attrs:{"dense":"","items":item.prices,"headers":_vm.priceHeaders,"items-per-page":-1,"sort-desc":false,"sort-by":"fromDate","height":"300px","fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseFractionUnitToString(item.value, item.currency))+" ")]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.fromDate))+" ")]}},{key:"item.untilDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.untilDate))+" ")]}}],null,true)})],1)],1)],1)],1)],1)]}}])}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Validity Periods")])],1),_c('v-data-table',{attrs:{"items":_vm.validityPeriods,"headers":_vm.validityPeriodsHeaders,"items-per-page":-1,"sort-desc":false,"sort-by":"fromDate","height":"300px","fixed-header":"","hide-default-footer":"","disable-pagination":"","data-test-id":"validityPeriodsTable"},scopedSlots:_vm._u([{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.fromDate))+" ")]}},{key:"item.untilDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.untilDate))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }