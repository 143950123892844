var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pagination-container"},[(!_vm.textButtons || (_vm.page && _vm.page > 1))?_c('button',{class:{
      'v-pagination__navigation': true,
      'pagination-previous-btn': true,
      'text-btn': _vm.textButtons,
    },attrs:{"type":"button","data-test-id":"prevPageBtn","disabled":_vm.previousBtnDisabled},on:{"click":function($event){_vm.page--}}},[_c('v-icon',{attrs:{"left":_vm.textButtons,"color":"blue","disabled":_vm.previousBtnDisabled}},[_vm._v("mdi-menu-left")]),(_vm.textButtons)?_c('div',{class:{
        'blue--text': true,
        'text--disabled': _vm.previousBtnDisabled,
      }},[_vm._v(" PREVIOUS ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"pagination-input"},[(!_vm.hidePageInput)?_c('input',{staticClass:"v-pagination__item pagination-number-input",attrs:{"disabled":(_vm.hasTotalPages && _vm.totalPages < 2) || _vm.disabled,"max":_vm.totalPages,"type":"number","min":"1","step":"1","data-test-id":"currentPageInput"},domProps:{"value":_vm.page},on:{"change":_vm.handlePageChange}}):_vm._e(),(_vm.hasTotalPages)?_c('div',{staticClass:"pagination-total",attrs:{"data-test-id":"totalPages"}},[_vm._v(" / "+_vm._s(_vm.totalPages)+" ")]):_vm._e()]),(!_vm.textButtons || !_vm.totalPages || _vm.page < _vm.totalPages)?_c('button',{class:{
      'v-pagination__navigation': true,
      'pagination-after-btn': true,
      'text-btn': _vm.textButtons,
    },attrs:{"type":"button","data-test-id":"nextPageBtn","disabled":_vm.nextBtnDisabled},on:{"click":function($event){_vm.page++},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}}},[(_vm.textButtons)?_c('div',{class:{
        'blue--text': true,
        'text--disabled': _vm.nextBtnDisabled,
      }},[_vm._v(" NEXT ")]):_vm._e(),_c('v-icon',{attrs:{"right":_vm.textButtons,"color":"blue","disabled":_vm.nextBtnDisabled}},[_vm._v("mdi-menu-right")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }