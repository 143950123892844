<template>
  <v-container
    v-if="schema"
    fluid
    class="schema-attributes-reader"
    :data-test-id="'schemaReader_' + path"
  >
    <v-row dense class="main-title-row">
      <v-col>
        <div class="d-flex flex-column">
          <div
            v-if="breadcrumbPath"
            class="breadcrumb text-truncate"
            :title="breadcrumbPath"
          >
            {{ breadcrumbPath }}
          </div>
          <div
            data-test-id="schemaName"
            :title="schemaName"
            class="d-flex align-center"
          >
            <div class="schema-title font-weight-bold text-truncate mr-1">
              {{ schemaName }}
            </div>
            <div v-if="required" class="rounded-circle white required-status">
              <v-icon x-small color="primary" title="Property is required">
                mdi-star
              </v-icon>
            </div>
            <div
              v-else-if="nonRequired"
              class="rounded-circle white required-status"
            >
              <v-icon x-small color="warning" title="Property is NOT required">
                mdi-star-off
              </v-icon>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="3" class="d-flex font-weight-medium align-center">
        <v-divider vertical class="mx-2" />
        <div class="text-truncate" data-test-id="schemaType" :title="type">
          {{ type }}
        </div>
      </v-col>
    </v-row>

    <!-- TITLE -->
    <template v-if="hasTitle">
      <v-row dense class="title-row">
        <v-col cols="3" class="font-weight-medium">Title</v-col>
        <v-col data-test-id="schemaTitleI18nKey">{{
          schema.title.i18nKey
        }}</v-col>
      </v-row>
      <v-row
        v-for="(locale, index) in shownLocales"
        dense
        class="justfy-center"
        :key="'title' + index + locale"
      >
        <v-col
          cols="3"
          class="font-weight-medium"
          :data-test-id="'schemaTitle_' + locale"
        >
          {{ locale }}
        </v-col>
        <v-col :data-test-id="'schemaTitle_' + locale + '_value'">
          <div
            class="text-truncate"
            :title="getValue('title.values.' + locale)"
          >
            {{ getValue("title.values." + locale) }}
          </div>
        </v-col>
      </v-row>
    </template>

    <!-- DESCRIPTION -->
    <template v-if="hasDescription">
      <v-row dense class="title-row">
        <v-col cols="3" class="font-weight-medium">Description</v-col>
        <v-col data-test-id="schemaDescriptionI18nKey">{{
          schema.description.i18nKey
        }}</v-col>
      </v-row>
      <v-row
        v-for="(locale, index) in shownLocales"
        :key="'description' + index + locale"
        dense
        class="justfy-center"
      >
        <v-col
          cols="3"
          class="font-weight-medium"
          :data-test-id="'schemaDescription_' + locale"
        >
          {{ locale }}
        </v-col>
        <v-col :data-test-id="'schemaDescription_' + locale + '_value'">
          <div
            class="text-truncate"
            :title="getValue('description.values.' + locale)"
          >
            {{ getValue("description.values." + locale) }}
          </div>
        </v-col>
      </v-row>
    </template>

    <!-- ATTRIBUTES -->
    <template v-if="attributes.length > 0">
      <v-row dense class="title-row">
        <v-col cols="3" class="font-weight-medium">Attributes</v-col>
      </v-row>
      <template v-for="attribute in attributes">
        <v-row v-if="hasAttribute(attribute)" dense :key="attribute">
          <v-col
            cols="3"
            class="font-weight-medium text-truncate"
            :title="attribute"
            :data-test-id="'schemaAttribute_' + attribute"
          >
            {{ attribute }}
          </v-col>
          <v-col v-if="attribute === 'enumOptions'">
            <DataReader
              disable-json
              title="Enum options"
              data-test-id="schemaAttributeEnumOptions"
              :data="schema[attribute]"
              :schema="enumOptionsSchema"
              :initial-closed="schema.enumOptions.length >= 5"
            />
          </v-col>
          <v-col
            v-else
            :data-test-id="'schemaAttribute_' + attribute + '_value'"
          >
            {{ renderAttribute(attribute) }}
          </v-col>
        </v-row>
      </template>
    </template>

    <!-- OPTION ENUM VALUES -->
    <template v-if="hasOptionEnumValues">
      <v-row dense class="title-row">
        <v-col class="font-weight-medium">Option Enum Values</v-col>
      </v-row>
      <v-row dense>
        <v-data-table
          dense
          class="option-enum-table"
          sort-by="sortOrder"
          hide-default-footer
          :sort-desc="false"
          :headers="optionEnumValuesHeader"
          :items="schema.optionEnumValues"
          :items-per-page="-1"
        />
      </v-row>
    </template>

    <!-- PROPERTIES -->
    <template v-else-if="hasProperties || requiredPropertiesNotInSchema">
      <v-row dense class="properties">
        <v-col class="mt-2">
          <div class="font-weight-medium">Properties</div>
          <template v-if="requiredPropertiesNotInSchema">
            <v-row class="px-3 warning--text">
              <v-col cols="4" class="d-flex align-center"
                >Required properties not included in schema</v-col
              >
              <v-col>
                <v-icon left color="warning"> mdi-alert </v-icon>
                {{ requiredPropertiesNotInSchema.join(", ") }}
              </v-col>
            </v-row>
          </template>
          <template v-if="hasProperties">
            <SchemaAttributesReader
              v-for="(child, property) in properties"
              :key="property"
              :property="property"
              :schema="child"
              :required="isRequired(property)"
              :non-required="isNonRequired(property)"
              :path="path + '.' + property"
            />
          </template>
        </v-col>
      </v-row>
    </template>

    <!-- ITEMS -->
    <template v-else-if="hasItems">
      <v-row dense class="items">
        <v-col class="mt-2">
          <div class="font-weight-medium">Items</div>
          <SchemaAttributesReader
            property="Items"
            :schema="schema.items"
            :path="path + '.items'"
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import DataReader from "../../data/Reader";
export default {
  inject: {
    getShownLocales: {
      default() {
        return () => null;
      },
    },

    addWarningMessage: {
      default() {
        return () => null;
      },
    },
  },

  components: {
    SchemaAttributesReader: () => import("./SchemaAttributesReader"),
    DataReader,
  },

  props: {
    schema: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    property: {
      type: String,
      required: false,
      default: "ROOT",
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    nonRequired: {
      type: Boolean,
      required: false,
      default: false,
    },

    path: {
      type: String,
      required: false,
      default: "$",
    },
  },

  async mounted() {
    await this.$nextTick();
    const missingProperties = this.requiredPropertiesNotInSchema;
    if (!missingProperties || missingProperties.length === 0) return;
    const message =
      "Following required properties do not exist in the schema: " +
      missingProperties.join(", ") +
      "";
    this.addWarningMessage(this.path, message);
  },

  methods: {
    isRequired(property) {
      const requiredArray = this.schema?.required;
      return requiredArray && requiredArray.includes(property);
    },

    isNonRequired(property) {
      const nonRequiredArray = this.schema?.nonRequired;
      return nonRequiredArray && nonRequiredArray.includes(property);
    },

    hasAttribute(attribute) {
      return Object.prototype.hasOwnProperty.call(this.schema, attribute);
    },

    renderAttribute(attribute) {
      const attr = this.schema?.[attribute];
      if (attr === undefined || attr === null) return undefined;
      if (Array.isArray(attr)) {
        return attr.join(", ");
      }
      return attr;
    },

    getValue(path) {
      return this.$getObjectValueByPath(this.schema, path) ?? "-";
    },
  },

  computed: {
    schemaName() {
      return this.property;
    },

    type() {
      return this.schema?.type?.toUpperCase?.();
    },

    hasTitle() {
      const title = this.schema?.title;
      return !!title && this.$isPlainObject(title);
    },

    hasDescription() {
      const description = this.schema?.description;
      return !!description && this.$isPlainObject(description);
    },

    attributes() {
      return this.schemaAttributes.filter((attribute) =>
        this.hasAttribute(attribute)
      );
    },

    properties() {
      return this.schema?.properties;
    },

    hasProperties() {
      return !!this.properties && this.$isPlainObject(this.properties);
    },

    requiredPropertiesNotInSchema() {
      const required = this.schema?.required ?? [];
      const properties = this.hasProperties ? this.properties : {};
      const propertyNames = Object.keys(properties);
      const notIncluded = required.filter((property) => {
        return !propertyNames.includes(property);
      });

      return notIncluded.length > 0 ? notIncluded : null;
    },

    hasItems() {
      const items = this.schema?.items;
      return !!items && this.$isPlainObject(items);
    },

    enumOptionsSchema() {
      return {
        type: "array",
        items: {
          type: "object",
          additionalProperties: true,
        },
      };
    },

    hasOptionEnumValues() {
      return (
        this.schema.type === "optionEnum" &&
        this.hasAttribute("optionEnumValues")
      );
    },

    optionEnumValuesHeader() {
      return [
        { value: "code", text: "Code" },
        { value: "command", text: "Command" },
        { value: "adminLabel", text: "Admin Label" },
        { value: "i18nLabel", text: "Label I18n Key" },
        { value: "i18nShortLabel", text: "Short Label I18n Key" },
        { value: "sortOrder", text: "Sort Order" },
        { value: "visible", text: "Visible" },
      ];
    },

    breadcrumbPath() {
      const paths = this.path?.split?.(".") ?? [];
      const breadcrumbs = paths.filter(
        (path) => path !== "$" && path !== this.property
      );
      if (breadcrumbs.length > 0) {
        return breadcrumbs.join(" > ") + " > ";
      }
      return null;
    },

    schemaAttributes() {
      return [
        //String
        "format",
        "minLength",
        "maxLength",
        "pattern",
        "enumOptions",

        //Number/Integer
        "minimum",
        "maximum",
        "exclusiveMinimum",
        "exclusiveMaximum",
        "multipleOf",

        //Array
        "minItems",
        "maxItems",
        "uniqueItems",

        //All
        "default",
      ];
    },

    supportedLocales() {
      return this.$store.state.localization.supportedLocales;
    },

    shownLocales() {
      const shownLocales = this.getShownLocales();
      if (!shownLocales) return this.supportedLocales;
      return shownLocales;
    },
  },
};
</script>

<style scoped>
.schema-attributes-reader {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-right: 0;
  width: calc(100% - 8px);
  white-space: break-spaces;
  overflow-wrap: anywhere;
}

.schema-attributes-reader > .row {
  border-left: 2px solid black;
  position: relative;
}

.schema-attributes-reader > .title-row:not(:last-child) {
  border-top: 0.5px solid grey;
}

.schema-attributes-reader > .row:first-child {
  border-top-left-radius: 2px;
}

.schema-attributes-reader > .row:last-child {
  border-bottom-left-radius: 2px;
}

.schema-attributes-reader > .row:first-child::before {
  content: "";
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
}

.schema-attributes-reader > .row:last-child::after {
  content: "";
  background: black;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 24px;
  height: 2px;
}

.schema-attributes-reader > .main-title-row {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
}

.schema-attributes-reader > .main-title-row .v-divider {
  border-color: white;
}

.schema-attributes-reader > .main-title-row .breadcrumb {
  font-size: 0.75rem;
}

.schema-attributes-reader > .title-row {
  background-color: rgba(0, 0, 0, 0.1);
}

.schema-attributes-reader .v-subheader {
  height: 24px;
}

.schema-attributes-reader .option-enum-table,
.schema-attributes-reader .option-enum-table::v-deep > .v-data-table__wrapper {
  display: flex;
  flex: 1 1 100%;
}

.required-status {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>