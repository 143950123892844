<template>
  <DetailView
    class="product-detail"
    :title="title"
    :loading="runningAction"
    :tabs="tabs"
    @close="$emit('close', false)"
  >
    <!-- eslint-disable-next-line -->
    <template #tab.overview="{ loading }">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row>
          <v-col>
            <v-card
              flat
              outlined
              class="product-detail-properties-card"
              data-test-id="productPropertiesCard"
            >
              <v-container
                v-if="editable"
                fluid
              >
                <v-text-field
                  v-model="product.sku"
                  dense
                  outlined
                  label="SKU"
                />
                <v-text-field
                  v-model="product.sps"
                  dense
                  outlined
                  label="Service provider"
                />
                <v-text-field
                  v-model="product.externalId"
                  dense
                  outlined
                  label="External ID"
                />
              </v-container>
              <v-container
                v-else
                fluid
              >
                <v-list>
                  <v-list-item>
                    <v-list-item-icon><v-icon>mdi-widgets</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.sku }}
                      </v-list-item-title>
                      <v-list-item-subtitle>SKU</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon><v-icon>mdi-cube-scan</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.productType }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Product Type</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon><v-icon>mdi-hand-coin</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.serviceProviderId }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Service provider</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon><v-icon>mdi-identifier</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.externalId }}
                      </v-list-item-title>
                      <v-list-item-subtitle>External ID</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="catalog">
                    <v-list-item-icon><v-icon>mdi-book-open-variant</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <a
                          @click="openCatalog"
                          data-test-id="productCatalog"
                        >
                          {{ catalog.name }}
                        </a>
                      </v-list-item-title>
                      <v-list-item-subtitle>Catalog</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="labels.length > 0">
          <v-col>
            <v-card
              flat
              outlined
              class="product-detail-card"
            >
              <v-card-title>
                <v-icon left>mdi-translate</v-icon> Name and Description
              </v-card-title>
              <v-treeview
                :items="labels"
                open-on-click
                open-all
                dense
              >
                <template v-slot:label="{ item }">
                  <span v-if="!item.language">{{ item.name }}</span>
                  <v-row
                    v-else
                    dense
                  >
                    <v-col cols="3">{{ item.language + ":" }}</v-col>
                    <v-col cols="9">{{ item.name }}</v-col>
                  </v-row>
                </template>
              </v-treeview>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <!-- PRICE TIMELINE -->
            <v-card
              flat
              outlined
              v-if="!editable"
              class="product-detail-timeline-card"
            >
              <v-card-title>
                <v-icon left>mdi-currency-usd</v-icon>
                <span>Price Information</span>
                <v-spacer />
                <v-switch
                  v-model="showPriceLists"
                  label="Show Pricelists"
                  data-test-id="showPriceListSwitch"
                />
              </v-card-title>

              <PriceCalendar
                v-if="!showPriceLists"
                :loading="runningAction"
                :key="product.sku"
                :prices="prices"
                :validityPeriods="{ [product.sku]: validityPeriods }"
                data-test-id="productPriceCalendar"
              />
              <PriceListViewer
                v-else
                :product="product"
                data-test-id="priceDetail"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card
              outlined
              class="product-detail-card"
              data-test-id="productDimensionAttributesCard"
            >
              <v-card-title>
                <v-icon left>mdi-chart-scatter-plot</v-icon>
                Dimension Attributes
              </v-card-title>
              <v-container>
                <v-list two-line>
                  <v-list-item
                    v-for="(attr, index) in dimensionAttributes"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ attr }} </v-list-item-title>
                      <v-list-item-subtitle>{{ index }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-container>
              <v-card-title>
                <v-icon left>mdi-translate</v-icon>
                Localizations
              </v-card-title>
              <DataReader
                :data="dimensionLabels"
                :schema="{
                  type: 'object',
                  additionalProperties: true,
                }"
                title="dimensionLabels"
                hide-root-node
                disable-json
                initial-closed
                class="px-3 pb-3"
              />
            </v-card>
          </v-col>
          <v-col>
            <GenericAttributesCard
              :key="product.sku"
              v-if="product.attributes"
              title="Attributes"
              icon="mdi-format-list-bulleted-square"
              :attributes="product.attributes"
              data-test-id="productAttributesCard"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.configuration="{ loading }">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card
              flat
              outlined
              class="d-flex flex-column"
            >
              <v-card-title>
                Data

                <PatternHeaderMenu
                  :items="dataConfigs"
                  no-data-text="No configurations found"
                  @click:item="(config) => openConfiguration(config.key, 'data')
                    "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      text
                      small
                      color="primary"
                      class="ml-4"
                    >
                      <v-icon left>mdi-link</v-icon>
                      Configurations ({{ dataConfigs.length }})
                    </v-btn>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title :class="{
                        'font-weight-black': item.key === product.sku,
                        'font-italic font-weight-light':
                          item.key === product.sku && item.exists,
                      }">
                        {{ item.key }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </PatternHeaderMenu>
              </v-card-title>
              <DataReader
                :data="configData"
                :schema="productDataSchema"
                data-test-id="productData"
                class="pa-3"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card
              flat
              outlined
              class="d-flex flex-column"
            >
              <v-card-title>
                Schema

                <PatternHeaderMenu
                  :items="schemaConfigs"
                  no-data-text="No configurations found"
                  @click:item="(config) => openConfiguration(config.key, 'schema')
                    "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      text
                      small
                      color="primary"
                      class="ml-4"
                    >
                      <v-icon left>mdi-link</v-icon>
                      Configurations ({{ schemaConfigs.length }})
                    </v-btn>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title :class="{
                        'font-weight-black': item.key === product.sku,
                        'font-italic font-weight-light':
                          item.key === product.sku && item.exists,
                      }">
                        {{ item.key }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </PatternHeaderMenu>
              </v-card-title>
              <SchemaReader
                :schema="configSchema"
                class="pa-3"
                data-test-id="productSchema"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </DetailView>
</template>

<script>
import DetailView from "../common/templates/DetailView";
import PriceCalendar from "./PriceCalendar";
import GenericAttributesCard from "../common/display-helpers/GenericAttributesCard";
import PriceListViewer from "./PriceListsViewer";
import esMixin from "mixins/elastic-search-mixin";
import csMixin from "mixins/config-service-mixin";
import PatternHeaderMenu from "../product-type/pattern/PatternHeaderMenu";
import DataReader from "../configuration/data/Reader";
import SchemaReader from "../configuration/schema/reader/Reader";

export default {
  mixins: [esMixin, csMixin],

  props: {
    value: {
      type: Object,
      required: true,
    },

    editable: {
      type: Boolean,
      requried: false,
      default: false,
    },

    index: {
      type: String,
      required: false,
    },
  },

  components: {
    DetailView,
    PriceCalendar,
    GenericAttributesCard,
    PriceListViewer,
    PatternHeaderMenu,
    DataReader,
    SchemaReader,
  },

  data() {
    return {
      product: this.value,
      catalog: null,
      dimensionAttributes: null,
      labels: [],
      labelTreeId: 0,
      showPriceLists: false,
      priceLists: {},
      dimensionLabels: {},
      selectedTab: 0,
      prices: [],
      schemaConfigs: [],
      dataConfigs: [],
      configData: null,
      configSchema: null,
      productDataSchema: null,
      runningAction: false,
    };
  },

  async mounted() {
    await this.init();
  },

  watch: {
    "value.sku": async function () {
      this.product = this.value;
      await this.init();
    },

    product() {
      this.$emit("input", this.product);
    },
  },

  methods: {
    async init() {
      try {
        this.runningAction = true;
        this.sortAttributes();
        await this.getCatalogAndPrices();
        await this.loadConfiguration();
      } finally {
        this.runningAction = false;
      }
    },

    async getCatalogAndPrices() {
      if (!this.editable) {
        const idx1 = this.index.indexOf(".");
        const idx2 = this.index.lastIndexOf(".");
        const id = this.index.substring(idx1 + 1, idx2);

        const catalog = await this.$store.$coreApi.coreCatalogApi.getCatalog(
          this.selectedDomain,
          id
        );

        if (!catalog) {
          this.$store.commit(
            "SET_ERROR",
            "Catalog with id " + id + " could not be found"
          );
          return;
        }

        this.catalog = catalog;

        let query = this.searchRequestBody({
          limit: 10000,
          sort: [{ "sku.keyword": "asc" }, { fromDate: "asc" }],
          _source: ["sku", "value", "currency", "fromDate", "untilDate"],
        });

        //create a nested boolean query,
        //because the result should match one of the given SKUs
        query.query.bool.must.push(this.skuFilterQuery(this.product.sku));
        const result = await this.$store.$coreApi.coreElasticSearchApi.search({
          domain: this.selectedDomain,
          indexType: "price",
          catalogId: catalog.id,
          indexVersion: catalog.indexVersion,
          catalogVersion: catalog.catalogVersion,
          body: query,
        });
        this.prices = result?.items ?? [];
      }
    },

    sortAttributes() {
      const productName = this.product?.productName;
      if (this.$isPlainObject(productName)) {
        this.labels = this.labels.concat(this.getLabels(productName, "NAME"));
      }
      const description = this.product?.description;
      if (this.$isPlainObject(description)) {
        this.labels = this.labels.concat(
          this.getLabels(description, "DESCRIPTION")
        );
      }

      const variationAttributes = this.product?.variationAttributes;
      if (this.$isPlainObject(variationAttributes)) {
        let dimensionAttributes = new Map();
        this.dimensionLabels = {};

        Object.entries(variationAttributes).forEach(([key, value]) => {
          //Distinct between dimension attributes and their labels
          const code = value?.code;
          if (!code) return;
          dimensionAttributes.set(key, code);
          const labels = value?.labels ?? {};
          const shortLabels = value?.shortLabels ?? {};
          const translations = {
            Labels: labels,
            "Short Labels": shortLabels,
          };
          this.$set(this.dimensionLabels, key, translations);
        });

        this.dimensionAttributes = Object.fromEntries(dimensionAttributes);
      }
    },

    getLabels(labelObject, name) {
      let labels = [];
      let languages = [];

      //Add the language dependent labels as children
      Object.entries(labelObject).forEach(([lang, text]) => {
        lang = lang.replace("_", "-"); //change the seperator of the locale
        languages.push({
          id: this.labelTreeId,
          name: text,
          language: lang,
        });
        this.labelTreeId++;
      });

      //Add label to tree
      labels.push({
        id: this.labelTreeId,
        name,
        children: languages,
      });
      this.labelTreeId++;

      return labels;
    },

    async loadConfiguration() {
      const sku = this.product.sku;
      const schemaRule = await this.loadMergedSchema(
        this.selectedDomain,
        "ProductType",
        this.product.productType + ".product.data",
        true
      );
      this.productDataSchema = schemaRule.schema;
      const merged =
        await this.$store.$coreApi.coreConfigurationApi.getMergedData(
          this.selectedDomain,
          "SKU",
          sku,
          this.productDataSchema
        );

      this.configData = merged?.data;

      //load merged schema
      const mergedSchemaRule = await this.loadMergedSchema(
        this.selectedDomain,
        "SKU",
        sku,
        true
      );
      this.configSchema = mergedSchemaRule.schema;

      //Load configuration relations
      let lookupRes =
        await this.$store.$coreApi.coreConfigurationApi.configLookup(
          this.selectedDomain,
          "SKU",
          sku,
          {
            includeParentDomains: false,
          }
        );

      if (!lookupRes) lookupRes = [];

      //check if there is an existing configuration for the current product
      const dataConfigExists = lookupRes.some(
        (res) => res.source === "data" && res.key === sku
      );
      const schemaConfigExists = lookupRes.some(
        (res) => res.source === "schema" && res.key === sku
      );

      //split the lookup results in data and schema
      let schemaConfigs = lookupRes
        .filter((res) => res.source === "schema" && res.key != sku)
        .sort((c1, c2) => c1.specificity - c2.specificity);
      let dataConfigs = lookupRes
        .filter((res) => res.source === "data" && res.key != sku)
        .sort((c1, c2) => c1.specificity - c2.specificity);

      //add the current product sku to the data and schema sources
      dataConfigs.push({
        key: sku,
        keySpace: "SKU",
        source: "data",
        exists: dataConfigExists,
      });

      schemaConfigs.push({
        key: sku,
        keySpace: "SKU",
        source: "schema",
        exists: schemaConfigExists,
      });

      this.schemaConfigs = schemaConfigs;
      this.dataConfigs = dataConfigs;
    },

    openConfiguration(pattern, source) {
      this.$router.push({
        name: source === "data" ? "productData" : "productSchema",
        params: {
          pattern: pattern,
          productType: this.product.productType,
        },
      });
      return;
    },

    openCatalog() {
      this.$router.push({
        name: "catalogDetail",
        params: {
          catalog: this.catalog.id,
        },
      });
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    tabs() {
      return [
        { text: "Overview", value: "overview" },
        { text: "Configuration", value: "configuration" },
      ];
    },

    validityPeriods() {
      return this.product?.attributes?.validityPeriods;
    },

    title() {
      return this.product.sku + " (" + this.product.productName["en-GB"] + ")";
    },
  },
};
</script>

<style scoped>
.product-detail {
  display: flex;
  overflow-y: hidden;
  padding: 0;
}

.product-detail-properties-card {
  text-align: left;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
}

.product-detail-timeline-card {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  margin: 10px 0px 10px 0px;
  padding: 10px;
}

.product-detail-scroll-container {
  width: 100%;
  height: calc(100% - 124px);
  align-self: flex-start;
  top: 0px;
  align-items: flex-start;
  overflow-y: scroll;
  margin-top: 12px;
}

.product-detail-card {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column wrap;
  height: 100%;
  text-align: left;
}
</style>