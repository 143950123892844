export default {

    computed: {

        catalogRules(){
            return {
                required: (val) => !!val || 'Value is required',
                idPattern: (val) => {
                    const pattern = "^[a-z][a-z,0-9]{1,20}$";
                    const regex = new RegExp(pattern);
                    const valid = regex.test(val);
                    return valid || 'Must match pattern ' + pattern;
                }
            }
        },
    },


}