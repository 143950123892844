<template>
    <v-container class="price-list-viewer">
        <v-toolbar flat>
            <v-toolbar-title>Price Lists</v-toolbar-title>
        </v-toolbar>
        <v-data-table 
            :items="priceLists"
            :headers="headers"
            :items-per-page="-1"
            :sort-desc="false"
            sort-by="fromDate"
            :expanded="expanded"
            hide-default-footer
            show-expand
            data-test-id="priceListTable"
        >

            <!-- eslint-disable-next-line -->
            <template v-slot:item.data-table-expand="{item, isExpanded, expand}">
                <v-btn
                    icon
                    v-if="isExpanded"
                    @click="expand(false)"
                    :data-test-id="'price_list_hide_btn_' + item.id"
                >
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn
                    icon
                    v-else
                    @click="expand(true)"
                    :data-test-id="'price_list_expand_btn_' + item.id"
                >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.fromDate="{item}">
                {{getDate(item.fromDate)}}
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.untilDate="{item}">
                {{getDate(item.untilDate)}}
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length+1">
                    <v-container class="price-list-detail" :data-test-id="'price_list_detail_' + item.id">
                        <v-row>
                            <v-col>
                                <v-card flat outlined :data-test-id="'price_list_name_' + item.id">
                                    <v-card-title>
                                        <v-icon left>mdi-translate</v-icon>
                                        Name
                                    </v-card-title>
                                    <v-list>
                                        <v-list-item v-for="(name, index) in getNames(item)" :key="index">
                                            <v-list-item-content>
                                                <v-list-item-title> 
                                                    {{name.name}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>{{name.language}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                            <v-col v-if="item.attributes">
                                <GenericAttributesCard 
                                    :key="item.id"
                                    title="Attributes"
                                    icon="mdi-format-list-bulleted-square"
                                    :attributes="item.attributes"
                                    :data-test-id="'price_list_attributes_' + item.id" 
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card flat outlined :data-test-id="'price_list_prices_' + item.id">
                                    <v-card-title>Prices</v-card-title>
                                    <v-data-table 
                                        dense
                                        :items="item.prices"
                                        :headers="priceHeaders"
                                        :items-per-page="-1"
                                        :sort-desc="false"
                                        sort-by="fromDate"
                                        height="300px"
                                        fixed-header
                                        disable-pagination
                                        hide-default-footer
                                    >
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.price="{item}">
                                            {{$parseFractionUnitToString(item.value, item.currency)}}
                                        </template>

                                       <!-- eslint-disable-next-line -->
                                        <template v-slot:item.fromDate="{item}">
                                            {{getDate(item.fromDate)}}
                                        </template>

                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.untilDate="{item}">
                                            {{getDate(item.untilDate)}}
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </td>
            </template>
        </v-data-table>

        <v-toolbar flat>
            <v-toolbar-title>Validity Periods</v-toolbar-title>
        </v-toolbar>

        <v-data-table 
            :items="validityPeriods"
            :headers="validityPeriodsHeaders"
            :items-per-page="-1"
            :sort-desc="false"
            sort-by="fromDate"
            height="300px"
            fixed-header
            hide-default-footer
            disable-pagination
            data-test-id="validityPeriodsTable"
        >

            <!-- eslint-disable-next-line -->
            <template v-slot:item.fromDate="{item}">
                {{getDate(item.fromDate)}}
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.untilDate="{item}">
                {{getDate(item.untilDate)}}
            </template>

        </v-data-table>
    </v-container>
</template>

<script>
import GenericAttributesCard from "../common/display-helpers/GenericAttributesCard"

export default {
    
    components: {
        GenericAttributesCard
    },

    props: {
        product: {
            type: Object,
            required: true
        }
    },

    data(){
        return {
            priceLists: [],
            validityPeriods: [],
            expanded: [],
        }
    },

    mounted(){
        this.sortPrices();
    },

    methods: {

        sortPrices(){
            //create an array of priceList objects with their respective prices 
            const prices = this.product?.prices;
            if(!prices) return;
            let priceListMap = new Map();

            for(let price of prices){
                const plId = price.priceListId;
                let priceList = priceListMap.get(plId);
                if(!priceList) priceList = { prices: []};
                priceList.prices.push(price);
                priceListMap.set(plId, priceList);
            }
            
            for(let pl of this.product.priceLists){
                const priceList = priceListMap.get(pl.id);
                if(priceList) priceListMap.set(pl.id, Object.assign(priceList, pl));
            }

            this.priceLists = Array.from(priceListMap.values());

            this.validityPeriods = this.product?.attributes?.validityPeriods;
        },

        getNames(priceList){
            let languages = [];
            //Get the price list name translations
            Object.entries(priceList.name).forEach(([lang, text]) => {
                languages.push({
                    name: text,
                    language: this.languageNames.of(lang)
                })
            });
            return languages;
        },

        getDate(dateString){
            return this.$getLocalizedDate(dateString, {year: 'numeric', month: 'numeric', day: 'numeric'})
        }

    },

    computed: {

        headers(){
            return [
                {text: 'Price list ID', value: 'id'}, 
                {text: 'External ID', value: 'externalId'},
                {text: 'From Date', value: 'fromDate'}, 
                {text: 'Until Date', value: 'untilDate'}
            ];
        },

        priceHeaders(){
            return [
                {text: 'Price', value: 'price'}, 
                {text: 'From Date', value: 'fromDate'}, 
                {text: 'Until Date', value: 'untilDate'}
            ];
        },

        validityPeriodsHeaders(){
            return [
                {text: 'From Date', value: 'fromDate'}, 
                {text: 'Until Date', value: 'untilDate'}
            ]
        },

        languageNames(){
            return new Intl.DisplayNames(['en'], {type: 'language'})
        }
    }

}
</script>

<style scoped>

.price-list-viewer{
    text-align: left;
}

.price-list-viewer .price-list-detail .v-card{
    height: 100%;
}

.price-list-viewer .v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded__row{
    background-color: var(--v-psblue-base);
    color: white;
}

.price-list-viewer .v-data-table::v-deep > .v-data-table__wrapper > table > tbody> tr.v-data-table__expanded__row:hover{
    color: black;
}

.price-list-viewer > .v-data-table{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 6px 0px;
}
</style>